import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  TradeWorkflowSpec,
  TradeWorkflowSpecApi,
  TradeWorkflowSpecUpdateParams
} from '@pinnakl/poems/trade-workflow-spec/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { Observable, map, tap } from 'rxjs';

type UpdateTradeWorkflowSpec = TradeWorkflowSpecUpdateParams & Pick<TradeWorkflowSpec, 'id'>;

@Injectable({
  providedIn: 'root'
})
export class TradeWorkflowSpecApiService {
  private readonly tradeWorkflowSpecEndpoint = `${V3_ENDPOINT}oms_config`;

  constructor(
    private readonly wsp: WebServiceProvider,
    private readonly toast: PinnaklUIToastMessage
  ) {}

  getTradeWorkflowSpec(): Observable<TradeWorkflowSpec> {
    return this.wsp
      .get<TradeWorkflowSpecApi[]>({
        endpoint: this.tradeWorkflowSpecEndpoint
      })
      .pipe(
        tap(values => {
          if (values.length === 0) {
            this.toast.error('No trade workflow spec found');
            throw new Error('No trade workflow spec found');
          }
        }),
        map(values => values[0])
      );
  }

  updateTradeWorkflowSpec(
    tradeWorkflowSpec: UpdateTradeWorkflowSpec
  ): Observable<TradeWorkflowSpec> {
    return this.wsp.put<TradeWorkflowSpec>({
      endpoint: this.tradeWorkflowSpecEndpoint,
      body: tradeWorkflowSpec
    });
  }

  createTradeWorkflowSpec(
    tradeWorkflowSpec: Partial<TradeWorkflowSpec>
  ): Observable<TradeWorkflowSpec> {
    return this.wsp.post<TradeWorkflowSpec>({
      endpoint: this.tradeWorkflowSpecEndpoint,
      body: tradeWorkflowSpec
    });
  }
}
