<ng-container *ngIf="vm$ | async as vm; else loading">
  <div
    (mousedown)="mouseDownEvent($event)"
    [ngClass]="{ 'cursor-move': !vm.minimizedView }"
    class="flex justify-content-between align-items-center"
  >
    <div class="flex align-items-center">
      <prime-button
        (onClick)="closeModal('searchSecurity')"
        [class.p-disabled]="vm.disableSearchSecurities || vm.minimizedView"
        [disabled]="vm.minimizedView"
        [outlined]="true"
        class="back-btn"
        icon="pi pi-search"
        severity="secondary"
      ></prime-button>
      <div class="ml-3 ticker-label">{{ vm.ticker }}</div>
    </div>
    <div class="p-dialog-header-icons">
      <prime-button
        (onClick)="minimizeDialog()"
        *ngIf="!vm.minimizedView"
        [text]="true"
        class="mr-2"
        icon="pi pi-minus"
        pTooltip="Minimize dialog"
        severity="secondary"
        tooltipPosition="bottom"
      ></prime-button>
      <prime-button
        (onClick)="maximizeDialog()"
        *ngIf="vm.minimizedView"
        [text]="true"
        class="mr-2"
        icon="pi pi-stop"
        pTooltip="Maximize dialog"
        severity="secondary"
        tooltipPosition="bottom"
      ></prime-button>
      <prime-button
        (keydown.enter)="closeModal()"
        (onClick)="closeModal()"
        [text]="true"
        class="mr-2"
        icon="pi pi-times"
        severity="secondary"
      ></prime-button>
    </div>
  </div>

  <div
    *ngIf="availableAccountsList"
    class="mt-4 account-multi-selector-container"
  >
    <prime-account-multi-selector
      [formControl]="selectedAccountsControl"
      [options]="availableAccountsList"
      [overlayOptions]="{
        appendTo: 'body'
      }"
      optionLabel="accountCodeLabel"
      placeholder="Select account code"
    ></prime-account-multi-selector>
  </div>

  <p-tabView
    [activeIndex]="1"
    class="mt-4 block"
  >
    <p-tabPanel
      [disabled]="!!isExposureSummaryDisabled"
      header="Exposure Summary"
    >
      <pinnakl-security-details-exposure-summary
        (saveNewMarkValue)="saveNewMarkValue($event)"
        (saveNewPrevCloseValue)="saveNewPrevCloseValue($event)"
      ></pinnakl-security-details-exposure-summary>
    </p-tabPanel>
    <p-tabPanel header="Trade History">
      <pinnakl-security-details-trade-history
        [securityMarketId]="vm.securityMarketId"
        [underlyingSecurityId]="vm.underlyingSecurityId"
      ></pinnakl-security-details-trade-history>
    </p-tabPanel>
    <p-tabPanel
      [disabled]="!!isFoldersDisabled"
      header="Folder Comments"
    >
      <pinnakl-security-details-folder-comments></pinnakl-security-details-folder-comments>
    </p-tabPanel>
    <p-tabPanel header="Position History">
      <pinnakl-security-details-position-history></pinnakl-security-details-position-history>
    </p-tabPanel>
  </p-tabView>
</ng-container>

<ng-template #loading>
  <pinnakl-local-spinner-simple-mode-container
    containerHeight="400px"
    size="200px"
  ></pinnakl-local-spinner-simple-mode-container>
</ng-template>
