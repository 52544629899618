<ng-container *ngIf="content">
  <ng-container *ngIf="isContentHtml; else plainText">
    <peek-html
      (viewChanged)="handleViewChanged($event)"
      [html]="content"
      [peekLength]="peekLength"
      textClass="white-space-initial overflow-auto"
    ></peek-html>
  </ng-container>
  <ng-template #plainText>
    <peek-text
      (viewChanged)="handleViewChanged($event)"
      [peekLength]="peekLength"
      [text]="content"
      textClass="white-space-pre-wrap"
    ></peek-text>
  </ng-template>
</ng-container>
