import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { SecurityDetailsModalFacadeService } from '@pinnakl/poems/modals/security-details/data-access';
import { TradesFacadeService } from '@pinnakl/poems/trades/data-access';
import { ExtendedTradeFlat } from '@pinnakl/poems/trades/domain';
import {
  ColDef,
  DragStartedEvent,
  DragStoppedEvent,
  GetMainMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  ValueFormatterParams
} from 'ag-grid-community';
import moment from 'moment/moment';
import { BehaviorSubject, combineLatest, switchMap, tap } from 'rxjs';
import { TranTypeCellRendererComponent } from './tran-type-cell/tran-type-cell.component';

@Component({
  selector: 'pinnakl-security-details-trade-history',
  templateUrl: './security-details-trade-history.component.html',
  styleUrls: ['./security-details-trade-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityDetailsTradeHistoryComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private gridApi?: GridApi<ExtendedTradeFlat>;
  isInDragState$ = new BehaviorSubject(false);
  trades$ = new BehaviorSubject<ExtendedTradeFlat[]>([]);
  loading$ = new BehaviorSubject(true);
  isUnderlyingControl = new FormControl(false);
  columnDefs: ColDef[] = [];
  defaultColDef = {
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    filter: true
  };
  @Input() securityMarketId?: string;
  @Input() underlyingSecurityId?: string;

  constructor(
    private readonly decimalPipe: DecimalPipe,
    private readonly tradesFacadeService: TradesFacadeService,
    private readonly securityDetailsModalFacadeService: SecurityDetailsModalFacadeService
  ) {
    this.handleUnderlyingSecurityExisting();
    this.columnDefs = this.buildColDefs();
  }

  ngOnInit(): void {
    combineLatest([
      this.isUnderlyingControl.valueChanges,
      this.securityDetailsModalFacadeService.selectedAccounts$
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.loading$.next(true)),
        switchMap(([isUnderlying, selectedAccounts]) =>
          this.tradesFacadeService.getTradesFlat(
            (isUnderlying ? this.underlyingSecurityId : this.securityMarketId) ?? '',
            isUnderlying ? 'underlyingsecid' : 'securitymarketid',
            selectedAccounts.length ? selectedAccounts : undefined
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(data => {
        this.trades$.next(data);
        this.loading$.next(false);
      });

    this.isUnderlyingControl.patchValue(false);
  }

  gridReady(params: GridReadyEvent<ExtendedTradeFlat>) {
    this.gridApi = params.api;

    setTimeout(() => {
      this.gridApi?.autoSizeAllColumns(false);
    }, 1000);
  }

  exportToExcel(): void {
    this.gridApi?.exportDataAsExcel();
  }

  getMainMenuItems(params: GetMainMenuItemsParams): (string | MenuItemDef)[] {
    return [
      ...params.defaultItems.slice(0, 4),
      {
        name: 'Fit columns to screen',
        action: () => params.api.sizeColumnsToFit()
      },
      ...params.defaultItems.slice(4)
    ];
  }

  dragStarted(event: DragStartedEvent): void {
    if (event.target.className.includes('ag-header-cell')) this.isInDragState$.next(true);
  }

  dragStopped(event: DragStoppedEvent): void {
    if (event.target.className.includes('ag-header-cell')) this.isInDragState$.next(false);
  }

  private buildColDefs(): ColDef[] {
    return [
      {
        field: 'parsedTradeDate',
        headerName: 'Trade Date',
        width: 100,
        maxWidth: 118,
        suppressSizeToFit: true,
        sort: 'desc',
        valueFormatter: (cell: ValueFormatterParams) => {
          const { value } = cell;
          try {
            return value ? moment(value).utc().format('MM/DD/YYYY') : '';
          } catch (e) {
            return value;
          }
        }
      },
      {
        field: 'ticker',
        headerName: 'Ticker'
      },
      {
        field: 'parsedTranType',
        headerName: 'Tran',
        cellRenderer: TranTypeCellRendererComponent
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        cellClass: 'ag-right-aligned-cell',
        comparator: (valueA, valueB) => +valueA - +valueB,
        valueFormatter: ({ value }) => this.decimalPipe.transform(value, '1.0') ?? ''
      },
      {
        field: 'executionPrice',
        headerName: 'Price',
        cellClass: 'ag-right-aligned-cell',
        comparator: (valueA, valueB) => +valueA - +valueB,
        valueFormatter: ({ value }) => this.decimalPipe.transform(value, '1.0-4') ?? ''
      },
      {
        field: 'settlementCurrency',
        headerName: 'Currency'
      },
      {
        field: 'commissionInput',
        headerName: 'CPS',
        cellClass: 'ag-right-aligned-cell',
        valueFormatter: ({ value }) => this.decimalPipe.transform(value, '1.0-4') ?? ''
      },
      {
        field: 'netMoneyLocal',
        headerName: 'Net Money',
        cellClass: 'ag-right-aligned-cell',
        comparator: (valueA, valueB) => +valueA - +valueB,
        valueFormatter: ({ value }) => this.decimalPipe.transform(value, '1.0-2') ?? ''
      },
      {
        field: 'brokerName',
        headerName: 'Broker Name'
      },
      {
        field: 'folderCode',
        headerName: 'Folder'
      },
      {
        field: 'accountCode',
        headerName: 'Account',
        valueFormatter: ({ value, data }) =>
          data === undefined || data.accountCode === '' ? '' : value || 'Multiple'
      },
      {
        field: 'trader',
        headerName: 'Trader'
      },
      {
        field: 'comments',
        headerName: 'Comments'
      }
    ];
  }

  private handleUnderlyingSecurityExisting(): void {
    this.securityDetailsModalFacadeService.activeUnderlyingSecurityId$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(id =>
        this.isUnderlyingControl[id === undefined ? 'disable' : 'enable']({ emitEvent: false })
      );
  }
}
