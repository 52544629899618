<div class="trade-history-container">
  <div class="mt-3 mb-4 row no-gutters justify-content-between align-items-center">
    <prime-input-switch
      [formControl]="isUnderlyingControl"
      label="Show all trades in underlying"
      labelPos="right"
    ></prime-input-switch>

    <prime-button
      (onClick)="exportToExcel()"
      [outlined]="true"
      [rounded]="true"
      icon="pi pi-file-excel"
      label="Export to Excel"
      severity="secondary"
      size="sm"
    ></prime-button>
  </div>
  <div
    *ngIf="{
      trades: trades$ | async,
      loading: loading$ | async
    } as data"
    class="trades-grid-container relative"
  >
    <ag-grid-angular
      (dragStarted)="dragStarted($event)"
      (dragStopped)="dragStopped($event)"
      (gridReady)="gridReady($event)"
      [class.column-drag-state]="isInDragState$ | async"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [getMainMenuItems]="getMainMenuItems"
      [gridOptions]="{ rowGroupPanelShow: 'always' }"
      [rowData]="data.trades"
      [suppressDragLeaveHidesColumns]="true"
      class="ag-theme-business h-100"
    ></ag-grid-angular>
    <pinnakl-local-spinner
      *ngIf="data.loading"
      backgroundColor="rgba(255, 255, 255, 0.8)"
      size="75px"
    ></pinnakl-local-spinner>
  </div>
</div>
