/* eslint-disable @typescript-eslint/ban-types */
import { computed, inject, Signal, Type } from '@angular/core';
import {
  SignalStoreFeature,
  signalStoreFeature,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import {
  addEntities,
  addEntity,
  EntityId,
  removeEntities,
  removeEntity,
  setAllEntities,
  setEntity,
  updateEntity
} from '@ngrx/signals/entities';
import { EntityState, NamedEntitySignals } from '@ngrx/signals/entities/src/models';
import { StateSignal } from '@ngrx/signals/src/state-signal';
import { PatchHttpRequestPayload } from '@pinnakl/shared/types';
import {
  CallState,
  getCallStateKeys,
  setError,
  setLoadedMany,
  setLoadedOne,
  setLoadingMany,
  setLoadingOne,
  setProcessing
} from './call-state.feature';
import { patchState } from './with-devtools';

export type Filter = Record<string, unknown>;
export type Entity = { id: EntityId };
export type Payload = NonNullable<unknown>;
export type PayloadType<E extends Entity, P> = P extends undefined ? E : P extends Payload ? P : E;

export interface DataService<
  E extends Entity,
  F extends Filter,
  P extends Payload | undefined = undefined
> {
  load?(filter: F): Promise<E[] | { items: E[]; totalCount: number; next?: string | null }>;

  loadById?(id: number, filter?: F): Promise<E | undefined>;

  create?(entity: Partial<PayloadType<E, P>>, filter?: F): Promise<E>;

  updateById?(id: number, entity: Partial<PayloadType<E, P>>, filter?: F): Promise<E>;

  patchById?(
    id: number,
    patches: PatchHttpRequestPayload[] | PatchHttpRequestPayload,
    filter?: F
  ): Promise<E>;

  removeById?(id: number, entity?: Partial<E>, filter?: F): Promise<void>;

  removeMany?(ids: number[], filter?: F): Promise<void>;
}

// TODO figure out how to type singular form
export function singularize(word): string {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: ''
  };
  return word.replace(new RegExp(`(${Object.keys(endings).join('|')})$`), r => endings[r]);
}

export function capitalize(str: string, singular = false): string {
  const plural = str ? str[0].toUpperCase() + str.substring(1) : str;
  return singular ? singularize(plural) : plural;
}

export function getDataServiceKeys(options: { collection?: string }): {
  filterKey: string;
  totalCountKey: string;
  updateTotalCountKey: string;
  cursorKey: string;
  updateCursorKey: string;
  selectedIdsKey: string;
  selectedEntitiesKey: string;
  updateFilterKey: string;
  updateSelectedKey: string;
  setErrorKey: string;
  setLoadingKey: string;
  setLoadedKey: string;
  setProcessingKey: string;
  loadKey: string;
  loadByIdKey: string;
  currentKey: string;
  setCurrentKey: string;
  createKey: string;
  updateByIdKey: string;
  patchByIdKey: string;
  removeByIdKey: string;
  removeEntityByIdKey: string;
  removeManyKey: string;
  entitiesKey: string;
  entityMapKey: string;
  idsKey: string;
} {
  const filterKey = options.collection ? `${options.collection}Filter` : 'filter';
  const totalCountKey = options.collection ? `${options.collection}TotalCount` : 'totalCount';
  const updateTotalCountKey = options.collection
    ? `update${capitalize(options.collection)}TotalCount`
    : 'updateTotalCount';
  const cursorKey = options.collection ? `${options.collection}Cursor` : 'cursor';
  const updateCursorKey = options.collection
    ? `update${capitalize(options.collection)}Cursor`
    : 'updateCursor';
  const selectedIdsKey = options.collection
    ? `selected${capitalize(options.collection)}Ids`
    : 'selectedIds';
  const selectedEntitiesKey = options.collection
    ? `selected${capitalize(options.collection)}Entities`
    : 'selectedEntities';
  const updateFilterKey = options.collection
    ? `update${capitalize(options.collection)}Filter`
    : 'updateFilter';
  const updateSelectedKey = options.collection
    ? `updateSelected${capitalize(options.collection)}Entities`
    : 'updateSelected';

  const loadKey = options.collection ? `load${capitalize(options.collection)}Entities` : 'load';
  const setLoadedKey = options.collection
    ? `setLoaded${capitalize(options.collection)}Entities`
    : 'setLoaded';
  const setLoadingKey = options.collection
    ? `setLoading${capitalize(options.collection)}Entities`
    : 'setLoading';
  const setProcessingKey = options.collection
    ? `setProcessing${capitalize(options.collection)}Entities`
    : 'setProcessing';
  const setErrorKey = options.collection
    ? `setError${capitalize(options.collection)}Entities`
    : 'setError';
  const loadByIdKey = options.collection
    ? `loadById${capitalize(options.collection)}Entities`
    : 'loadById';
  const currentKey = options.collection ? `current${capitalize(options.collection)}` : 'current';
  const setCurrentKey = options.collection
    ? `setCurrent${capitalize(options.collection)}`
    : 'setCurrent';
  const createKey = options.collection
    ? `create${capitalize(options.collection)}Entities`
    : 'create';
  const updateByIdKey = options.collection
    ? `updateById${capitalize(options.collection)}Entities`
    : 'updateById';
  const patchByIdKey = options.collection
    ? `patchById${capitalize(options.collection)}Entities`
    : 'patchById';
  const removeByIdKey = options.collection
    ? `removeById${capitalize(options.collection)}Entities`
    : 'removeById';
  const removeEntityByIdKey = options.collection
    ? `remove${capitalize(options.collection)}EntityById`
    : 'removeEntityById';
  const removeManyKey = options.collection
    ? `removeMany${capitalize(options.collection)}Entities`
    : 'removeMany';

  // TODO: Take these from @ngrx/signals/entities, when they are exported
  const entitiesKey = options.collection ? `${options.collection}Entities` : 'entities';
  const entityMapKey = options.collection ? `${options.collection}EntityMap` : 'entityMap';
  const idsKey = options.collection ? `${options.collection}Ids` : 'ids';

  return {
    filterKey,
    totalCountKey,
    updateTotalCountKey,
    cursorKey,
    updateCursorKey,
    selectedIdsKey,
    selectedEntitiesKey,
    updateFilterKey,
    updateSelectedKey,
    setErrorKey,
    setLoadingKey,
    setLoadedKey,
    setProcessingKey,
    loadKey,
    loadByIdKey,
    currentKey,
    setCurrentKey,
    createKey,
    updateByIdKey,
    patchByIdKey,
    removeByIdKey,
    removeEntityByIdKey,
    removeManyKey,
    entitiesKey,
    entityMapKey,
    idsKey
  };
}

export type NamedDataServiceState<E extends Entity, F extends Filter, Collection extends string> = {
  [K in Collection as `${K}Filter`]: F;
} & {
  [K in Collection as `${K}Cursor`]: string | null;
} & {
  [K in Collection as `selected${Capitalize<K>}Ids`]: Record<EntityId, boolean>;
} & {
  [K in Collection as `current${Capitalize<K>}`]: E;
} & {
  [K in Collection as `${K}TotalCount`]: number;
};

export type DataServiceState<E extends Entity, F extends Filter> = {
  filter: F;
  cursor: string | null;
  selectedIds: Record<EntityId, boolean>;
  current: E;
  totalCount: number;
};

export type NamedDataServiceSignals<E extends Entity, Collection extends string> = {
  [K in Collection as `selected${Capitalize<K>}Entities`]: Signal<E[]>;
};

export type DataServiceSignals<E extends Entity> = {
  selectedEntities: Signal<E[]>;
};

export type NamedDataServiceMethods<
  E extends Entity,
  F extends Filter,
  Collection extends string,
  P extends Payload | undefined
> = {
  [K in Collection as `update${Capitalize<K>}Filter`]: (filter: F) => void;
} & {
  [K in Collection as `update${Capitalize<K>}TotalCount`]: (totalCountKey: number) => void;
} & {
  [K in Collection as `update${Capitalize<K>}Cursor`]: (cursor: string | null) => void;
} & {
  [K in Collection as `updateSelected${Capitalize<K>}Entities`]: (
    id: EntityId,
    selected: boolean
  ) => void;
} & {
  [K in Collection as `load${Capitalize<K>}Entities`]: (
    filter: F,
    forceRefetch?: boolean,
    concatItems?: boolean
  ) => Promise<E[]>;
} & {
  [K in Collection as `loadById${Capitalize<K>}Entities`]: (
    id: number,
    forceRefetch?: boolean,
    filter?: F,
    addToList?: boolean
  ) => Promise<E | undefined>;
} & {
  [K in Collection as `setCurrent${Capitalize<K>}`]: (entity: E | null) => void;
} & {
  [K in Collection as `create${Capitalize<K>}Entities`]: (
    entity: Partial<PayloadType<E, P>>,
    filter?: F
  ) => Promise<E | undefined>;
} & {
  [K in Collection as `updateById${Capitalize<K>}Entities`]: (
    id: number,
    entity: Partial<PayloadType<E, P>>,
    filter?: F
  ) => Promise<E | undefined>;
} & {
  [K in Collection as `patchById${Capitalize<K>}Entities`]: (
    id: number,
    patches: PatchHttpRequestPayload[] | PatchHttpRequestPayload,
    filter?: F
  ) => Promise<E | undefined>;
} & {
  [K in Collection as `removeById${Capitalize<K>}Entities`]: (
    id: number,
    entity?: Partial<E>,
    filter?: F
  ) => Promise<void>;
} & {
  [K in Collection as `remove${Capitalize<K>}EntityById`]: (id: number) => void;
} & {
  [K in Collection as `removeMany${Capitalize<K>}Entities`]: (
    ids: number[],
    filter?: F
  ) => Promise<void>;
};

export type DataServiceMethods<
  E extends Entity,
  F extends Filter,
  P extends Payload | undefined
> = {
  updateFilter: (filter: F) => void;
  updateTotalCount: (totalCount: number) => void;
  updateCursor: (cursor: string | null) => void;
  updateSelected: (id: EntityId, selected: boolean) => void;
  load: (filter: F) => Promise<void>;
  loadById: (id: EntityId, filter?: F) => Promise<E | undefined>;
  setCurrent: (entity: E | null) => void;
  create: (entity: PayloadType<E, P>, filter?: F) => Promise<void>;
  updateById: (id: EntityId, entity: Partial<PayloadType<E, P>>, filter?: F) => Promise<void>;
  patchById: (
    id: EntityId,
    patches: PatchHttpRequestPayload[] | PatchHttpRequestPayload,
    filter?: F
  ) => Promise<void>;
  removeById: (id: EntityId, entity?: Partial<E>, filter?: F) => Promise<void>;
  removeEntityById: (id: EntityId) => void;
  removeMany?: (ids: number[], filter?: F) => Promise<void>;
};

export function withDataService<
  E extends Entity,
  F extends Filter,
  S extends DataService<E, F, P>,
  Collection extends string,
  P extends Payload | undefined = undefined
>(options: {
  dataServiceType: Type<S>;
  filter: F;
  collection: Collection;
}): SignalStoreFeature<
  {
    state: {};
    // These alternatives break type inference:
    // state: { callState: CallState } & NamedEntityState<E, Collection>,
    // state: NamedEntityState<E, Collection>,
    signals: NamedEntitySignals<E, Collection>;
    methods: {};
  },
  {
    state: NamedDataServiceState<E, F, Collection>;
    signals: NamedDataServiceSignals<E, Collection>;
    methods: NamedDataServiceMethods<E, F, Collection, P>;
  }
>;
export function withDataService<
  E extends Entity,
  F extends Filter,
  S extends DataService<E, F, P>,
  P extends Payload | undefined = undefined
>(options: {
  dataServiceType: Type<S>;
  filter: F;
}): SignalStoreFeature<
  {
    state: { callState: CallState } & EntityState<E>;
    signals: {};
    methods: {};
  },
  {
    state: DataServiceState<E, F>;
    signals: DataServiceSignals<E>;
    methods: DataServiceMethods<E, F, P>;
  }
>;

export function withDataService<
  E extends Entity,
  F extends Filter,
  S extends DataService<E, F, P>,
  Collection extends string,
  P extends Payload | undefined = undefined
>(options: {
  dataServiceType: Type<S>;
  filter: F;
  collection?: Collection;
}): SignalStoreFeature<any, any> {
  const { dataServiceType, filter, collection: prefix } = options;
  const { callStateKey, updateLoadedOneKey, loadedOneKey, updateLoadedManyKey, loadedManyKey } =
    getCallStateKeys({ collection: prefix });
  const {
    entitiesKey,
    filterKey,
    totalCountKey,
    updateTotalCountKey,
    cursorKey,
    updateCursorKey,
    loadKey,
    loadByIdKey,
    currentKey,
    setCurrentKey,
    setLoadingKey,
    setLoadedKey,
    setErrorKey,
    setProcessingKey,
    createKey,
    updateByIdKey,
    patchByIdKey,
    removeByIdKey,
    removeEntityByIdKey,
    removeManyKey,
    selectedEntitiesKey,
    selectedIdsKey,
    updateFilterKey,
    updateSelectedKey
  } = getDataServiceKeys(options);

  return signalStoreFeature(
    withState(() => ({
      [filterKey]: filter,
      [selectedIdsKey]: {} as Record<EntityId, boolean>,
      [currentKey]: undefined as E | undefined,
      [totalCountKey]: 0,
      [cursorKey]: null
    })),
    withComputed((store: Record<string, unknown>) => {
      const entities = store[entitiesKey] as Signal<E[]>;
      const selectedIds = store[selectedIdsKey] as Signal<Record<EntityId, boolean>>;

      return {
        [selectedEntitiesKey]: computed(() => entities().filter(e => selectedIds()[e.id]))
      };
    }),
    withMethods((store: Record<string, unknown> & StateSignal<object>) => {
      const dataService = inject(dataServiceType);
      return {
        [updateCursorKey]: (cursor: string | null): void => {
          patchState(store, updateCursorKey, { [cursorKey]: cursor });
        },
        [updateFilterKey]: (filter: F): void => {
          patchState(store, updateFilterKey, { [filterKey]: filter });
        },
        [updateTotalCountKey]: (totalCount: number): void => {
          patchState(store, updateTotalCountKey, { [totalCountKey]: totalCount });
        },
        [updateSelectedKey]: (id: EntityId, selected: boolean): void => {
          patchState(store, updateSelectedKey, (state: Record<string, unknown>) => ({
            [selectedIdsKey]: {
              ...(state[selectedIdsKey] as Record<EntityId, boolean>),
              [id]: selected
            }
          }));
        },
        [removeEntityByIdKey]: (id: EntityId): void => {
          const updater = prefix ? removeEntity(id, { collection: prefix }) : removeEntity(id);
          patchState(store, removeEntityByIdKey, [updater]);
        },
        [loadKey]: async (
          filter: F,
          forceRefetch = false,
          concatItems = false
        ): Promise<E[] | undefined> => {
          if (!dataService.load) return Promise.resolve(undefined);
          const entities = store[entitiesKey] as Signal<E[]>;
          const entitiesInStore = entities();
          if (!forceRefetch && entitiesInStore.length > 0) return Promise.resolve(entitiesInStore);
          const filters = filter ?? (store[filterKey] as Signal<F>)();
          if (filters?.['cursor'] === null) delete filters['cursor'];
          store[callStateKey] &&
            patchState(store, setLoadingKey, setLoadingMany(prefix ?? undefined));
          try {
            const result = await dataService.load(filters);
            // Handle paginated results
            const entities = Array.isArray(result) ? result : result.items;
            const totalCount = Array.isArray(result) ? null : result.totalCount;
            const cursor = Array.isArray(result) ? null : result.next;
            let updater;
            // concatItems - needed for lazy loading then we add new items
            if (concatItems) {
              updater = prefix
                ? addEntities(entities, { collection: prefix })
                : addEntities(entities);
            } else {
              updater = prefix
                ? setAllEntities(entities, { collection: prefix })
                : setAllEntities(entities);
            }
            patchState(store, loadKey, updater);
            patchState(store, updateTotalCountKey, { [totalCountKey]: totalCount });
            patchState(store, updateCursorKey, { [cursorKey]: cursor });
            store[callStateKey] &&
              patchState(store, setLoadedKey, setLoadedMany(prefix ?? undefined));
            store[loadedManyKey] &&
              patchState(store, updateLoadedManyKey, { [loadedManyKey]: true });
            return entities;
          } catch (e) {
            store[callStateKey] && patchState(store, setErrorKey, setError(e, prefix ?? undefined));
            throw e;
          }
        },
        [loadByIdKey]: async (
          id: number,
          forceRefetch = false,
          filter?: F,
          addToList = false
        ): Promise<E | undefined> => {
          if (!dataService.loadById) return Promise.resolve(undefined);
          const current = store[currentKey] as Signal<E>;
          if (!forceRefetch && current()?.id === id) return Promise.resolve(current());
          store[callStateKey] &&
            patchState(store, setLoadingKey, setLoadingOne(prefix ?? undefined));
          const filters = filter ?? (store[filterKey] as Signal<F>)();
          try {
            const result = await dataService.loadById(id, filters);
            patchState(store, loadByIdKey, { [currentKey]: result });
            if (addToList && result) {
              const updater = prefix
                ? addEntity(result, { collection: prefix })
                : addEntity(result);
              patchState(store, createKey, updater);
            }
            store[callStateKey] &&
              patchState(store, setLoadedKey, setLoadedOne(prefix ?? undefined));
            store[loadedOneKey] && patchState(store, updateLoadedOneKey, { [loadedOneKey]: true });
            return result;
          } catch (e) {
            store[callStateKey] && patchState(store, setErrorKey, setError(e, prefix ?? undefined));
            throw e;
          }
        },
        [setCurrentKey]: (current: E | null): void => {
          patchState(store, setCurrentKey, { [currentKey]: current });
        },
        [createKey]: async (data: Partial<PayloadType<E, P>>, filter?: F): Promise<E> => {
          if (!dataService.create) throw new Error('Create is not implemented');
          store[callStateKey] &&
            patchState(store, setProcessingKey, setProcessing(prefix ?? undefined));
          const filters = filter ?? (store[filterKey] as Signal<F>)();
          try {
            const result = await dataService.create(data, filters);
            const updater = prefix ? setEntity(result, { collection: prefix }) : setEntity(result);
            patchState(store, createKey, updater);
            store[callStateKey] &&
              patchState(store, setLoadedKey, setLoadedOne(prefix ?? undefined));
            patchState(store, setCurrentKey, { [currentKey]: result });
            return result;
          } catch (e) {
            store[callStateKey] && patchState(store, setErrorKey, setError(e, prefix ?? undefined));
            throw e;
          }
        },
        [updateByIdKey]: async (
          id: number,
          data: Partial<PayloadType<E, P>>,
          filter?: F
        ): Promise<void> => {
          if (!dataService.updateById) return;
          store[callStateKey] &&
            patchState(store, setProcessingKey, setProcessing(prefix ?? undefined));
          const filters = filter ?? (store[filterKey] as Signal<F>)();
          try {
            const result = await dataService.updateById(id, data, filters);
            const updater = prefix
              ? updateEntity({ id, changes: result as Partial<E> }, { collection: prefix })
              : updateEntity({ id, changes: result as Partial<E> });
            const current = store[currentKey] as Signal<E>;
            if (current()?.id === id) {
              patchState(store, setCurrentKey, { [currentKey]: result });
            }
            patchState(store, updateByIdKey, updater);
            store[callStateKey] &&
              patchState(store, setLoadedKey, setLoadedOne(prefix ?? undefined));
          } catch (e) {
            store[callStateKey] && patchState(store, setErrorKey, setError(e, prefix ?? undefined));
            throw e;
          }
        },
        [patchByIdKey]: async (
          id: number,
          patches: PatchHttpRequestPayload[] | PatchHttpRequestPayload,
          filter?: F
        ): Promise<void> => {
          if (!dataService.patchById) return;
          store[callStateKey] &&
            patchState(store, setProcessingKey, setProcessing(prefix ?? undefined));
          const filters = filter ?? (store[filterKey] as Signal<F>)();
          try {
            const result = await dataService.patchById(id, patches, filters);
            const updater = prefix
              ? updateEntity({ id, changes: result as Partial<E> }, { collection: prefix })
              : updateEntity({ id, changes: result as Partial<E> });
            const current = store[currentKey] as Signal<E>;
            if (current()?.id === id) {
              patchState(store, setCurrentKey, { [currentKey]: result });
            }
            patchState(store, updateByIdKey, updater);
            store[callStateKey] &&
              patchState(store, setLoadedKey, setLoadedOne(prefix ?? undefined));
          } catch (e) {
            store[callStateKey] && patchState(store, setErrorKey, setError(e, prefix ?? undefined));
            throw e;
          }
        },
        [removeByIdKey]: async (id: number, data: Partial<E>, filter?: F): Promise<void> => {
          if (!dataService.removeById) return;
          store[callStateKey] &&
            patchState(store, setProcessingKey, setProcessing(prefix ?? undefined));
          const filters = filter ?? (store[filterKey] as Signal<F>)();
          try {
            await dataService.removeById(id, data, filters);
            const updater = prefix ? removeEntity(id, { collection: prefix }) : removeEntity(id);
            patchState(store, removeEntityByIdKey, updater);
            store[callStateKey] &&
              patchState(store, setLoadedKey, setLoadedOne(prefix ?? undefined));
            const totalCount = store[totalCountKey] as Signal<number>;
            totalCount() > 0 &&
              patchState(store, updateTotalCountKey, { [totalCountKey]: totalCount() - 1 });
          } catch (e) {
            store[callStateKey] && patchState(store, setErrorKey, setError(e, prefix ?? undefined));
            throw e;
          }
        },
        [removeManyKey]: async (ids: number[], filter?: F): Promise<void> => {
          if (!dataService.removeMany) return;
          store[callStateKey] &&
            patchState(store, setProcessingKey, setProcessing(prefix ?? undefined));
          const filters = filter ?? (store[filterKey] as Signal<F>)();
          try {
            await dataService.removeMany(ids, filters);
            const updater = prefix
              ? removeEntities(ids, { collection: prefix })
              : removeEntities(ids);
            patchState(store, removeManyKey, updater);
            store[callStateKey] &&
              patchState(store, setLoadedKey, setLoadedMany(prefix ?? undefined));
          } catch (e) {
            store[callStateKey] && patchState(store, setErrorKey, setError(e, prefix ?? undefined));
            throw e;
          }
        }
      };
    })
  );
}
