import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { Observable } from 'rxjs';

export interface ClientBenchMark {
  id: number;
  benchmark: string;
}

@Injectable()
export class BenchmarksApiService {
  private readonly _benchmarksEndpoint = 'v3/entities/client_benchmarks';
  private readonly _benchmarksReturnsEndpoint = 'v3/entities/benchmark_returns';

  constructor(private readonly wsp: WebServiceProvider) {}

  getBenchmarks(clientPublicId: string | null): Observable<ClientBenchMark[]> {
    const targetFundClientIdFilters = {
      filters: [
        {
          key: 'clientPublicId',
          type: 'EQ',
          value: [clientPublicId]
        }
      ]
    };
    return this.wsp.get({
      endpoint: this._benchmarksEndpoint,
      optionsParams: {
        fields: ['id', 'benchmark'],
        ...(clientPublicId ? targetFundClientIdFilters : {})
      }
    });
  }

  getOrganizationComparisonStats(): Observable<any> {
    return this.wsp.get({
      endpoint: this._benchmarksReturnsEndpoint,
      optionsParams: {
        fields: ['id', 'benchmarkid', 'date', 'monthlyreturn']
      }
    });
  }

  getOrganizationComparisonStatsById(fundId: number): Observable<any> {
    return this.wsp.get<any>({
      endpoint: this._benchmarksReturnsEndpoint,
      optionsParams: {
        fields: ['id', 'benchmarkid', 'date', 'monthlyreturn'],
        filters: [
          {
            key: 'benchmarkid',
            type: 'EQ',
            value: [fundId]
          }
        ]
      }
    });
  }

  updateOrganizationPerformanceData(payload: any): Observable<any> {
    return this.wsp.put<any>({
      endpoint: this._benchmarksReturnsEndpoint,
      body: payload
    });
  }

  createOrganizationPerformanceData(payload: any): Observable<any> {
    return this.wsp.post<any>({
      endpoint: this._benchmarksReturnsEndpoint,
      body: payload
    });
  }
}
