import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FolderComment } from '@pinnakl/poems/folders/domain';
import { SecurityDetailsModalFacadeService } from '@pinnakl/poems/modals/security-details/data-access';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pinnakl-security-details-folder-comments',
  templateUrl: './security-details-folder-comments.component.html',
  styleUrls: ['./security-details-folder-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityDetailsFolderCommentsComponent {
  vm$: Observable<{
    loading: boolean;
    folderComments: FolderComment[];
  }>;

  constructor(private readonly securityDetailsModalFacade: SecurityDetailsModalFacadeService) {
    this.vm$ = this.securityDetailsModalFacade.folderComments$.pipe(
      map(comments => ({
        loading: comments === undefined,
        folderComments: comments ?? []
      }))
    );
  }
}
