import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecurityDetailsModalFacadeService } from '@pinnakl/poems/modals/security-details/data-access';
import {
  ExposureSummaryInfo,
  MarkOverrideData,
  RealtimePriceOverrideCreateObj
} from '@pinnakl/poems/modals/security-details/domain';
import { RealtimePriceOverrideTypes } from '@pinnakl/poems/pricing/domain';
import { SecurityMarketDetails } from '@pinnakl/securities/domain';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pinnakl-security-details-exposure-summary',
  templateUrl: './security-details-exposure-summary.component.html',
  styleUrls: ['./security-details-exposure-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityDetailsExposureSummaryComponent {
  editMarkForm = new FormGroup({
    editMarkType: new FormControl<RealtimePriceOverrideTypes | null>(
      RealtimePriceOverrideTypes.DAY
    ),
    editMarkValue: new FormControl<string>('', { nonNullable: true })
  });
  editPreviousCloseForm = new FormGroup({
    editPreviousCloseValue: new FormControl<string | null>(null, Validators.required),
    editPreviousCloseCurrency: new FormControl<string | null>(null, Validators.required)
  });
  vm$: Observable<{
    security: SecurityMarketDetails;
    markOverrideData?: MarkOverrideData;
    positionsBase: ExposureSummaryInfo;
  }>;
  isTodayPriceInEditMode = false;
  isClosePriceInEditMode = false;

  @Output() saveNewMarkValue = new EventEmitter<RealtimePriceOverrideCreateObj | null>();

  @Output() saveNewPrevCloseValue = new EventEmitter<{
    value: number;
    currency: string;
  } | null>();

  constructor(
    private readonly securityDetailsModalFacadeService: SecurityDetailsModalFacadeService
  ) {
    this.vm$ = combineLatest([
      this.securityDetailsModalFacadeService.exposureSummaryInfo$.pipe(
        filter(isNeitherNullNorUndefined)
      ),
      this.securityDetailsModalFacadeService.markOverrideData$,
      this.securityDetailsModalFacadeService.securityObject$.pipe(filter(isNeitherNullNorUndefined))
    ]).pipe(
      map(([positionsBase, markOverrideData, security]) => ({
        positionsBase,
        markOverrideData,
        security
      })),
      tap(({ positionsBase, markOverrideData }) => {
        if (!this.isTodayPriceInEditMode) {
          this.editMarkForm.patchValue({
            editMarkValue: (
              markOverrideData?.price ??
              positionsBase.mark ??
              positionsBase.closeLocal
            ).toString(),
            editMarkType: markOverrideData?.type ?? this.editMarkForm.value.editMarkType
          });
        }

        if (!this.isClosePriceInEditMode) {
          const previousCloseValue = positionsBase.closeLocal ?? null;

          this.editPreviousCloseForm.patchValue({
            editPreviousCloseValue: previousCloseValue?.toString(),
            editPreviousCloseCurrency: positionsBase.currency
          });
        }
      }),
      takeUntilDestroyed()
    );
  }

  markValueWasChanged(newValue: RealtimePriceOverrideCreateObj | null, currencyCode: string): void {
    this.saveNewMarkValue.emit(
      newValue
        ? {
            ...newValue,
            currencyCode
          }
        : null
    );
  }

  prevCloseValueWasChanged(
    newValue: {
      value: number;
      currency: string;
    } | null,
    currencyCode: string
  ): void {
    this.saveNewPrevCloseValue.emit(
      newValue
        ? {
            ...newValue,
            currency: currencyCode
          }
        : newValue
    );
  }

  resetForm(formName: 'today' | 'previousClose'): void {
    if (formName === 'today') {
      this.editMarkForm.reset();
    } else if (formName === 'previousClose') {
      this.editPreviousCloseForm.reset();
    }
  }

  toggleIsTodayPriceInEditMode(isEditMode: boolean): void {
    this.isTodayPriceInEditMode = isEditMode;
  }

  getPreviousClosePriceLabel(dateStr?: string): string {
    return dateStr
      ? this.isYesterday(dateStr)
        ? 'YESTERDAY'
        : 'DATE:'
      : 'No previous close price';
  }

  toggleIsClosePriceInEditMode(isEditMode: boolean): void {
    this.isClosePriceInEditMode = isEditMode;
  }

  private isYesterday(dateStr: string): boolean {
    const date = new Date(dateStr);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }
}
