import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'peek-text',
  templateUrl: 'peek-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeekTextComponent {
  @Input() peekLength = 100;
  @Input() text = '';
  @Input() textClass = '';
  @Output() viewChanged = new EventEmitter<{ isExpanded: boolean }>();
  preview: string;
  showAllText: boolean;

  ngOnChanges() {
    if (!this.text) return;
    this.setValues();
  }

  setValues(): void {
    this.showAllText = this.text.length <= this.peekLength;
    this.preview = this.sliceText(this.text);
  }

  sliceText(text: string): string {
    return text.slice(0, this.peekLength);
  }

  expand() {
    this.showAllText = true;
    this.viewChanged.emit({ isExpanded: true });
  }

  collapse() {
    this.showAllText = false;
    this.viewChanged.emit({ isExpanded: false });
  }
}
