import { DecimalPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PositionsDetailsMap } from '@pinnakl/poems/modals/security-details/domain';
import { SortByPipe } from '@pinnakl/shared/ui/directives-and-pipes';

@Component({
  standalone: true,
  selector: 'pinnakl-positions-table',
  styleUrls: ['./positions-table.component.scss'],
  templateUrl: './positions-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgForOf, DecimalPipe, SortByPipe, NgClass]
})
export class PositionsTableComponent {
  @Input() type: 'Longs' | 'Shorts' = 'Longs';
  @Input() fullCustodiansList: { code: string; id: number }[] = [];
  @Input({ required: true }) positionsMapDetails!: PositionsDetailsMap;
}
