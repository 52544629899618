<ng-container *ngIf="vm$ | async as vm; else dataLoading">
  <div class="exposure-summary-container p-1">
    <div class="grid grid-nogutter">
      <div class="col-6">
        <div class="details-label">Position</div>
        <div class="sub-label mt-2">TODAY</div>
        <div class="position-value mt-2">
          {{ vm.positionsBase.totalPosition | number: '.0-1' }}
        </div>
      </div>

      <div class="col-6">
        <div class="details-label flex align-items-center">
          Price (
          <i
            [inlineSVG]="vm.security.primaryCurrency.code | currencyIcon"
            class="w-1rem h-1rem flex mx-1"
          ></i>
          {{ vm.security.primaryCurrency.code }}
          )
        </div>
        <div class="grid grid-nogutter mt-2">
          <div class="col-6 pr-2">
            <div class="sub-label">TODAY</div>
            <pinnakl-realtime-price
              (isEditMode)="toggleIsTodayPriceInEditMode($event)"
              (resetForm)="resetForm('today')"
              (saveNewMarkValue)="markValueWasChanged($event, vm.positionsBase.currency)"
              [editMarkForm]="editMarkForm"
              [markType]="vm.markOverrideData?.type"
              [price]="vm.markOverrideData?.price ?? vm.positionsBase.mark ?? 0"
            ></pinnakl-realtime-price>
          </div>
          <div class="col-6 pl-2">
            <div class="sub-label">
              {{ getPreviousClosePriceLabel(vm.positionsBase.priceDate) }}
              {{ vm.positionsBase.priceDate && (vm.positionsBase.priceDate | date: 'MM/dd/yyyy') }}
            </div>
            <pinnakl-previous-close-price
              (isEditMode)="toggleIsClosePriceInEditMode($event)"
              (saveNewPreviousCloseValue)="
                prevCloseValueWasChanged($event, vm.positionsBase.currency)
              "
              [editPreviousCloseForm]="editPreviousCloseForm"
              [price]="vm.positionsBase.closeLocal"
            ></pinnakl-previous-close-price>
          </div>
        </div>
      </div>
    </div>

    <div class="positions-table-section">Position by Account and Custodian</div>

    <ng-container *ngIf="vm.positionsBase.positionsMap.long">
      <pinnakl-positions-table
        [fullCustodiansList]="vm.positionsBase.fullCustodiansList"
        [positionsMapDetails]="vm.positionsBase.positionsMap.long"
        type="Longs"
      ></pinnakl-positions-table>
    </ng-container>
    <ng-container *ngIf="vm.positionsBase.positionsMap.short">
      <pinnakl-positions-table
        [fullCustodiansList]="vm.positionsBase.fullCustodiansList"
        [positionsMapDetails]="vm.positionsBase.positionsMap.short"
        type="Shorts"
      ></pinnakl-positions-table>
    </ng-container>
  </div>
</ng-container>

<ng-template #dataLoading>
  <pinnakl-local-spinner-simple-mode-container
    containerHeight="120px"
    size="70px"
  ></pinnakl-local-spinner-simple-mode-container>
</ng-template>
