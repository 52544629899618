import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'pinnakl-tran-type-cell',
  standalone: true,
  styles: [
    '.buy-color { background-color: #d9fde5 }',
    '.sell-color { background-color: #fdd9d9 }',
    '.short-color { background-color: #fcf2cf }',
    '.cover-color { background-color: #e1f1fF }'
  ],
  template: `<span class="px-2 py-1 border-round {{ type }}-color">{{ cellValue }}</span>`
})
export class TranTypeCellRendererComponent implements ICellRendererAngularComp {
  public type = '';
  public cellValue = '';

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.setTypeColor();
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    this.setTypeColor();
    return true;
  }

  private getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  private setTypeColor(): void {
    switch (this.cellValue) {
      case 'Buy':
        this.type = 'buy';
        break;
      case 'Sell':
        this.type = 'sell';
        break;
      case 'Short':
        this.type = 'short';
        break;
      case 'Cover':
        this.type = 'cover';
        break;
    }
  }
}
