import { DecimalPipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GrpcMessage } from '@ngx-grpc/common';
import { GrpcCoreModule, GrpcLoggerModule } from '@ngx-grpc/core';
import { GrpcWebClientModule } from '@ngx-grpc/grpc-web-client';
import { AppStateModule } from '@pinnakl/app-state';
import { DrawerModule } from '@pinnakl/atdl';
import { DEFAULTSCREEN, HttpConfigInterceptor, USERTYPE } from '@pinnakl/auth/providers';
import { AuthSecurityStorageService, AuthenticationModule } from '@pinnakl/authentication';
import { CoreDataProvidersModule } from '@pinnakl/core/data-providers';
import { EnvironmentModule } from '@pinnakl/core/environment';
import { AccountService, PMSMockFactory, SecurityService } from '@pinnakl/poems';
import { AumDataAccessModule } from '@pinnakl/poems/aum/data-access';
import { CommonDataDataAccessModule } from '@pinnakl/poems/data-access';
import { SecurityDetailsModalDataAccessModule } from '@pinnakl/poems/modals/security-details/data-access';
import { PoemsModalsSecurityDetailsFeatureModule } from '@pinnakl/poems/modals/security-details/feature';
import { PositionsDataAccessModule } from '@pinnakl/poems/positions/data-access';
import { PricingDataAccessModule } from '@pinnakl/poems/pricing/data-access';
import { PriceEventsStreamModule } from '@pinnakl/poems/streams/price/data-access';
import { TimeseriesDataAccessModule } from '@pinnakl/poems/timeseries/data-access';
import { TradeWorkflowSpecDataAccessModule } from '@pinnakl/poems/trade-workflow-spec/data-access';
import { TradesDataAccessModule } from '@pinnakl/poems/trades/data-access';
import { SharedUiModule } from '@pinnakl/shared-ui';
import { PinnaklGlobalSpinnerComponent } from '@pinnakl/shared/base-components';
import {
  AG_GRID_KEY,
  AG_GRID_KEY_TOKEN,
  HTTP_SERVER_URL,
  LOCATION,
  USE_PMS_MOCK
} from '@pinnakl/shared/constants';
import { UserTypes } from '@pinnakl/shared/types';
import { ClickOutsideDirective } from '@pinnakl/shared/ui/directives-and-pipes';
import { PrimeButtonComponent, UiToastMessageService } from '@pinnakl/shared/ui/prime';
import { PageSubscriptionsHandler, getAppInstanceToken } from '@pinnakl/shared/util-helpers';
import {
  FingerprintModule,
  InlineSVGModule,
  ModalMinimizeService,
  PinnaklErrorHandler,
  PinnaklUIToastMessage
} from '@pinnakl/shared/util-providers';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { AbstractSecurityStorage, AuthModule } from 'angular-auth-oidc-client';
import 'hammerjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const authConfig = {
  ...environment.authConfig,
  configId: getAppInstanceToken(
    environment.authConfig?.configId,
    location?.host,
    environment.appName
  )
};

const isDev = (): boolean =>
  localStorage.getItem('ngrxDevMode') === 'true' || !environment.production;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EnvironmentModule.forRoot({ config: environment }),
    GridModule,
    ExcelModule,
    AuthModule.forRoot({ config: authConfig }),
    AuthenticationModule,
    AppStateModule,
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument({
      autoPause: true,
      connectInZone: true,
      maxAge: isDev() ? false : 100
    }),
    SharedUiModule.register(environment.tradingServiceUrl),
    AppRoutingModule,
    DrawerModule,
    FingerprintModule,
    DialogModule,
    WindowModule,
    PoemsModalsSecurityDetailsFeatureModule,
    SecurityDetailsModalDataAccessModule,
    TradesDataAccessModule,
    TimeseriesDataAccessModule,
    PositionsDataAccessModule,
    AumDataAccessModule,
    PriceEventsStreamModule,
    PricingDataAccessModule,
    ToastModule,
    ConfirmDialogModule,
    CommonDataDataAccessModule,
    TradeWorkflowSpecDataAccessModule,
    ConfirmPopupModule,
    GrpcCoreModule.forRoot(),
    GrpcWebClientModule.forRoot({
      settings: { host: environment.grpcApiServerUrl }
    }),
    GrpcLoggerModule.forRoot({
      settings: {
        // enables logger in dev mode and still lets you see them in production when running
        // `localStorage.setItem('pinnaklGrpcLogger', 'true') in the console`
        enabled: localStorage.getItem('pinnaklGrpcLogger') === 'true',
        // protobuf json is more human-readable than the default toObject() mapping
        // please beware: if you use google.protobuf.Any you must pass the proper `messagePool` argument
        requestMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
        responseMapper: (msg: GrpcMessage) => msg.toProtobufJSON()
      }
    }),
    ClickOutsideDirective,
    PinnaklGlobalSpinnerComponent,
    InlineSVGModule,
    OverlayPanelModule,
    PrimeButtonComponent
  ],
  providers: [
    DecimalPipe,
    PercentPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: USE_PMS_MOCK,
      useFactory: PMSMockFactory()
    },
    {
      provide: HTTP_SERVER_URL,
      useValue: environment.coreApiServerUrl
    },
    {
      provide: LOCATION,
      useValue: location
    },
    {
      provide: ErrorHandler,
      useClass: PinnaklErrorHandler
    },
    {
      provide: AG_GRID_KEY_TOKEN,
      useValue: AG_GRID_KEY
    },
    { provide: DEFAULTSCREEN, useValue: { prod: 'dashboard', dev: 'dashboard' } },
    { provide: USERTYPE, useValue: UserTypes.INTERNAL },
    { provide: AbstractSecurityStorage, useClass: AuthSecurityStorageService },
    CoreDataProvidersModule,
    AccountService,
    DialogService,
    MessageService,
    ConfirmationService,
    {
      provide: PinnaklUIToastMessage,
      useClass: UiToastMessageService
    },
    SecurityService,
    ModalMinimizeService,
    PageSubscriptionsHandler
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
