import { Pipe, PipeTransform } from '@angular/core';
import { currencyIcons, ifKnownCurrency } from '@pinnakl/poems/domain';

@Pipe({
  name: 'currencyIcon',
  standalone: true,
  pure: true
})
export class CurrencyIconPipe implements PipeTransform {
  transform(value: string): string {
    return currencyIcons[ifKnownCurrency(value)];
  }
}
