import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  Folder,
  FolderFromApi,
  OldFolderDefinition,
  OldFolderDefinitionFromApi
} from '@pinnakl/poems/folders/domain';
import { cloneDeep } from 'lodash';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FoldersApiService {
  private readonly tradeFolderEndpoint = 'entities/folders';
  private readonly tradeRequestsEndpoint = 'entities/trades';
  private readonly foldersApi = 'entities/folders';
  private readonly mostRecentFolderEndpoint = 'markets';
  private readonly folderDefinitionApi = 'v3/entities/folder_definition';

  constructor(private readonly wsp: WebServiceProvider) {}

  static formatFolder(result: FolderFromApi): Folder {
    return {
      id: result.id,
      folderCode: result.col1,
      folderName: result.col2,
      strategy: result.col3,
      trader: result.col4,
      analyst: result.col5
    };
  }

  static formatCustomAttribute(result: OldFolderDefinitionFromApi): OldFolderDefinition {
    return {
      id: +result.id,
      label: result.label,
      fieldNumber: +result.fieldnumber
    };
  }

  static doesArrayContainObjectValue(
    arr: { name: string; id: string }[],
    ...comparisonArr: any
  ): boolean {
    return arr.reduce(
      (accum, item) =>
        accum
          ? accum
          : !!comparisonArr.find(compItem => item['name'] && compItem.name === item['name']),
      false
    );
  }

  static combineCustomAttributesWithCustomValues(
    customAttributesList: OldFolderDefinition[],
    allCustomValues: FolderFromApi[]
  ): OldFolderDefinition[] {
    const customAttributes = cloneDeep(customAttributesList);
    customAttributes.forEach(customAttribute => {
      const customValues: { name: string; id: string }[] = [];
      allCustomValues.forEach(allCustomValue => {
        const keysArr = Object.keys(allCustomValue);
        for (const i in keysArr) {
          if (
            keysArr[i] === 'col' + customAttribute.fieldNumber &&
            !this.doesArrayContainObjectValue(customValues, {
              name: allCustomValue[keysArr[i]]
            })
          ) {
            customValues.push({
              name: allCustomValue[keysArr[i]],
              id: allCustomValue.id
            });
          }
        }
      });
      customAttribute.customValues = customValues;
    });
    return customAttributes;
  }

  getFolders(): Observable<Folder[]> {
    return this.wsp
      .get<FolderFromApi[]>({
        endpoint: this.tradeFolderEndpoint,
        optionsParams: {
          fields: ['id', 'col1', 'col2', 'col3', 'col4', 'col5']
        }
      })
      .pipe(map(folders => folders.map(FoldersApiService.formatFolder)));
  }

  getFolderDefinitions(): Observable<OldFolderDefinition[]> {
    return this.wsp
      .get<OldFolderDefinitionFromApi[]>({
        endpoint: this.folderDefinitionApi,
        optionsParams: {
          fields: ['label', 'fieldNumber']
        }
      })
      .pipe(map(folderDenition => folderDenition.map(FoldersApiService.formatCustomAttribute)));
  }

  getAllCustomValues(): Observable<FolderFromApi[]> {
    return this.wsp.get<FolderFromApi[]>({
      endpoint: this.foldersApi,
      optionsParams: {
        fields: ['Col1', 'Col2', 'Col3', 'Col4', 'Col5']
      }
    });
  }

  createFolder(folder: Omit<FolderFromApi, 'id'>): Observable<Folder> {
    return this.wsp
      .post<FolderFromApi>({
        endpoint: this.foldersApi,
        body: {
          Col1: folder.col1,
          Col2: folder.col2,
          Col3: folder.col3,
          Col4: folder.col4,
          Col5: folder.col5
        }
      })
      .pipe(map(FoldersApiService.formatFolder));
  }

  updateFolder(body: Partial<FolderFromApi>): Observable<Folder> {
    return this.wsp
      .put<FolderFromApi>({
        endpoint: this.foldersApi,
        body
      })
      .pipe(map(FoldersApiService.formatFolder));
  }

  confirmDeleteForFolder(id: string): Observable<boolean> {
    return this.wsp
      .get<any[]>({
        endpoint: this.tradeRequestsEndpoint,
        optionsParams: {
          fields: ['id'],
          filters: [
            {
              key: 'folderid',
              type: 'EQ',
              value: [id]
            }
          ]
        }
      })
      .pipe(map(trades => !trades.length));
  }

  deleteFolder(id: string): Observable<void> {
    return this.wsp.delete({
      endpoint: `${this.foldersApi}/${id}`
    });
  }

  getMostRecentFolderEndpoint(id: string): Observable<string> {
    return this.wsp.get<string>({
      endpoint: `${this.mostRecentFolderEndpoint}/${id}/folders/most-recent`
    });
  }
}
