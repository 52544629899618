import { Injectable } from '@angular/core';
import { TargetFund } from '@pinnakl/crm/domain';
import { Observable } from 'rxjs';
import { FundPerformanceApiService } from './fund-performance-api.service';

@Injectable()
export class FundPerformanceFacadeService {
  constructor(private readonly api: FundPerformanceApiService) {}

  getTargetFunds(clientPublicId: string | null): Observable<TargetFund[]> {
    return this.api.getTargetFunds(clientPublicId);
  }

  getOrganizationPerformanceData(): Observable<any> {
    return this.api.getOrganizationPerformanceData();
  }

  getOrganizationPerformanceDataById(id: number): Observable<any> {
    return this.api.getOrganizationPerformanceDataById(id);
  }

  updateOrganizationPerformanceData(payload): Observable<any> {
    return this.api.updateOrganizationPerformanceData(payload);
  }

  createOrganizationPerformanceData(payload): Observable<any> {
    return this.api.createOrganizationPerformanceData(payload);
  }
}
