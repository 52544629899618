import { inject, Injectable } from '@angular/core';
import { AccountService, SearchSecurityItem, WideSearchComponent } from '@pinnakl/poems';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';

import { combineLatest, EMPTY, from, Subscription, take } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { Account } from '@pinnakl/poems/accounts/domain';
import { PoemsCommonDataFacadeService } from '@pinnakl/poems/data-access';
import { SecurityDetailsComponentData } from '@pinnakl/poems/modals/security-details/domain';
import { SecurityDetailsModalContainerComponent } from '@pinnakl/poems/modals/security-details/feature';
import { SecurityMarketsStore } from '@pinnakl/securities/data-access';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable()
export class NavBarService {
  private readonly securityMarketsStore = inject(SecurityMarketsStore);
  private positionSummarySubscription: Subscription;

  constructor(
    private readonly dialogService: DialogService,
    private readonly accountService: AccountService,
    private readonly poemsCommonDataFacadeService: PoemsCommonDataFacadeService,
    private readonly spinner: PinnaklSpinnerService
  ) {}

  searchModalOpen(closeCb?: (event: SearchSecurityItem) => void): void {
    this.dialogService
      .open(WideSearchComponent, {
        width: '632px',
        height: '611px',
        contentStyle: { 'border-radius': '8px', padding: '16px' },
        showHeader: false
      })
      .onClose.pipe(filter(v => !!v?.event))
      .subscribe(result =>
        closeCb ? closeCb(result.event) : this.openPositionSummaryDialog(result.event)
      );
  }

  private async openPositionSummaryDialog(item: SearchSecurityItem): Promise<void> {
    this.spinner.spin();
    this.poemsCommonDataFacadeService.loadFxRates();
    if (this.positionSummarySubscription) {
      this.positionSummarySubscription.unsubscribe();
    }

    const accounts$ = from(this.accountService.getAccounts());

    const securityDetails = await this.securityMarketsStore.loadSecurityMarketDetails(
      item.securityMarketId
    );

    this.positionSummarySubscription = combineLatest([
      accounts$,
      this.poemsCommonDataFacadeService.fxRates$.pipe(take(1))
    ])
      .pipe(
        catchError(() => {
          this.spinner.stop();
          return EMPTY;
        })
      )
      .subscribe(([accounts]) => {
        const componentData: SecurityDetailsComponentData = {
          ticker: securityDetails.market.ticker,
          selectedAccounts: [],
          availableAccounts: accounts.map(
            (acc): Account => ({
              id: acc.id,
              clientId: acc.clientId,
              name: acc.name,
              accountCode: acc.accountCode,
              accountCodeLabel: acc.accountCode.toUpperCase(),
              orderOfImportance: acc.orderOfImportance.toString(),
              isPrimaryForReturns: acc.isPrimaryForReturns,
              pbAccounts: acc.pbAccounts,
              startDate: new Date().toISOString(),
              endDate: new Date().toISOString()
            })
          ),
          underlyingSecurityId: securityDetails.underlyingSecId?.toString(),
          isExposureSummaryDisabled: true,
          disableSearchSecurities: false,
          folderId: undefined,
          securityMarketId: item.securityMarketId.toString()
        };
        this.dialogService
          .open(SecurityDetailsModalContainerComponent, {
            data: componentData,
            styleClass: 'security-details-modal',
            width: '1400px',
            position: 'top',
            draggable: true,
            modal: false,
            keepInViewport: true,
            maskStyleClass: 'z-index-1000',
            contentStyle: {
              'border-radius': '8px'
            },
            showHeader: false
          })
          .onClose.subscribe(type => {
            if (type === 'searchSecurity') {
              this.searchModalOpen();
            }
          });
        this.spinner.stop();
      });
  }
}
