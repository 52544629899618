<ng-container *ngIf="text">
  <div *ngIf="showAllText">
    <span [ngClass]="textClass">{{ text }}</span>
    <span
      (click)="collapse()"
      *ngIf="text.length > peekLength"
      class="cursor-pointer subject-person"
    >
      Hide
    </span>
  </div>
  <div *ngIf="!showAllText">
    <span
      [ngClass]="textClass"
      class="overflow-hidden"
      >{{ preview }}</span
    >
    <span
      (click)="expand()"
      *ngIf="text.length > peekLength"
      class="cursor-pointer subject-person"
    >
      ...More
    </span>
  </div>
</ng-container>
