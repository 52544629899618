<ng-container
  *ngIf="{
    label: type === 'Longs' ? 'Longs' : 'Shorts',
    colorType: type === 'Longs' ? 'long' : 'short'
  } as data"
>
  <div
    [ngClass]="{
      'w-10': fullCustodiansList.length > 2,
      'w-9': fullCustodiansList.length === 2,
      'w-8': fullCustodiansList.length === 1
    }"
    class="grid-position-type-label mt-3 flex align-items-center"
  >
    <div class="{{ data.colorType }}-background-color border-circle mr-2"></div>
    {{ data.label }}
  </div>
  <table
    [ngClass]="{
      'w-10': fullCustodiansList.length > 2,
      'w-9': fullCustodiansList.length === 2,
      'w-8': fullCustodiansList.length === 1
    }"
    class="table-layout-fixed positions-table"
  >
    <tr>
      <th class="positions-table-th {{ data.colorType }}">Account</th>
      <th
        *ngFor="let custodian of fullCustodiansList"
        class="positions-table-th text-right"
      >
        {{ custodian.code }}
      </th>
      <th class="positions-table-th text-right">Total</th>
      <th class="positions-table-th text-right">Market Value</th>
      <th class="positions-table-th text-right">MV%</th>
    </tr>
    <tr
      *ngFor="let accountDetails of positionsMapDetails.accounts | sortBy: 'asc' : 'code'"
      class="positions-table-tr"
    >
      <td class="positions-table-td positions-table-account-cell {{ data.colorType }}">
        <div class="account-chip py-1 px-2 border-round inline-block">
          {{ accountDetails.code }}
        </div>
      </td>
      <td
        *ngFor="let custodianDetails of fullCustodiansList"
        class="positions-table-td positions-table-number-cell text-right"
      >
        {{
          positionsMapDetails.quantityValues[accountDetails.id][custodianDetails.id] | number: '.0'
        }}
      </td>
      <td class="positions-table-td positions-table-number-cell text-right">
        {{ positionsMapDetails.totalQuantities[accountDetails.id] | number: '1.0-0' }}
      </td>
      <td class="positions-table-td positions-table-number-cell text-right">
        {{ positionsMapDetails.totalMarketValues[accountDetails.id] | number: '1.0-0' }}
      </td>
      <td class="positions-table-td positions-table-number-cell text-right">
        {{ positionsMapDetails.mvPctValues[accountDetails.id] | number: '1.0-2' }}%
      </td>
    </tr>
  </table>
</ng-container>
