import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { OverlayOptions, ScrollerOptions } from 'primeng/api';
import {
  MultiSelectBlurEvent,
  MultiSelectChangeEvent,
  MultiSelectFilterEvent,
  MultiSelectFocusEvent,
  MultiSelectLazyLoadEvent,
  MultiSelectRemoveEvent,
  MultiSelectSelectAllChangeEvent
} from 'primeng/multiselect';

@Component({ template: '', standalone: true })
export class MultiSelectPublicApiComponent {
  /**
   * Unique identifier of the component
   * @group Props
   */
  @Input() id: string | undefined;
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  @Input() ariaLabel: string | undefined;
  /**
   * Inline style of the element.
   * @group Props
   */
  @Input() style: { [klass: string]: any } | null | undefined;
  /**
   * Style class of the element.
   * @group Props
   */
  @Input() styleClass: string | undefined;
  /**
   * Inline style of the overlay panel.
   * @group Props
   */
  @Input() panelStyle: any;
  /**
   * Style class of the overlay panel element.
   * @group Props
   */
  @Input() panelStyleClass: string | undefined;
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  @Input() inputId: string | undefined;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  @Input() disabled: boolean | undefined;
  /**
   * When present, it specifies that the component cannot be edited.
   * @group Props
   */
  @Input() readonly: boolean | undefined;
  /**
   * Whether to display options as grouped when nested options are provided.
   * @group Props
   */
  @Input() group: boolean | undefined;
  /**
   * When specified, displays an input field to filter the items on keyup.
   * @group Props
   */
  @Input() filter = true;
  /**
   * Defines placeholder of the filter input.
   * @group Props
   */
  @Input() filterPlaceHolder: string | undefined;
  /**
   * Locale to use in filtering. The default locale is the host environment's current locale.
   * @group Props
   */
  @Input() filterLocale: string | undefined;
  /**
   * Specifies the visibility of the options panel.
   * @group Props
   */
  @Input() overlayVisible: boolean | undefined;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  @Input() tabindex: number | undefined = 0;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  @Input() appendTo: HTMLElement | ElementRef | TemplateRef<any> | string | null | undefined | any;
  /**
   * A property to uniquely identify a value in options.
   * @group Props
   */
  @Input() dataKey: string | undefined;
  /**
   * Name of the input element.
   * @group Props
   */
  @Input() name: string | undefined;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  @Input() ariaLabelledBy: string | undefined;
  /**
   * Whether to show labels of selected item labels or use default label.
   * @group Props
   * @defaultValue true
   */
  @Input() displaySelectedLabel!: boolean;
  /**
   * Decides how many selected item labels to show at most.
   * @group Props
   * @defaultValue 3
   */
  @Input() maxSelectedLabels: number | null | undefined;
  /**
   * Decides how many selected item labels to show at most.
   * @group Props
   */
  @Input() selectionLimit: number | undefined;
  /**
   * Label to display after exceeding max selected labels e.g. ({0} items selected), defaults "ellipsis" keyword to indicate a text-overflow.
   * @group Props
   */
  @Input() selectedItemsLabel = '{0} items selected';
  /**
   * Whether to show the checkbox at header to toggle all items at once.
   * @group Props
   */
  @Input() showToggleAll = true;
  /**
   * Text to display when filtering does not return any results.
   * @group Props
   */
  @Input() emptyFilterMessage = '';
  /**
   * Text to display when there is no data. Defaults to global value in i18n translation configuration.
   * @group Props
   */
  @Input() emptyMessage = '';
  /**
   * Clears the filter value when hiding the dropdown.
   * @group Props
   */
  @Input() resetFilterOnHide = true;
  /**
   * Icon class of the dropdown icon.
   * @group Props
   */
  @Input() dropdownIcon: string | undefined;
  /**
   * Name of the label field of an option.
   * @group Props
   */
  @Input() optionLabel: string | undefined;
  /**
   * Name of the value field of an option.
   * @group Props
   */
  @Input() optionValue: string | undefined;
  /**
   * Name of the disabled field of an option.
   * @group Props
   */
  @Input() optionDisabled: string | undefined;
  /**
   * Name of the label field of an option group.
   * @group Props
   */
  @Input() optionGroupLabel: string | undefined = 'label';
  /**
   * Name of the options field of an option group.
   * @group Props
   */
  @Input() optionGroupChildren = 'items';
  /**
   * Whether to show the header.
   * @group Props
   */
  @Input() showHeader = true;
  /**
   * When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
   * @group Props
   */
  @Input() filterBy: string | undefined;
  /**
   * Height of the viewport in pixels, a scrollbar is defined if height of list exceeds this value.
   * @group Props
   */
  @Input() scrollHeight = '200px';
  /**
   * Defines if data is loaded and interacted with in lazy manner.
   * @group Props
   */
  @Input() lazy = false;
  /**
   * Whether the data should be loaded on demand during scroll.
   * @group Props
   */
  @Input() virtualScroll: boolean | undefined;
  /**
   * Height of an item in the list for VirtualScrolling.
   * @group Props
   */
  @Input() virtualScrollItemSize: number | undefined;
  /**
   * Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
   * @group Props
   */
  @Input() virtualScrollOptions: ScrollerOptions | undefined;
  /**
   * Whether to use overlay API feature. The properties of overlay API can be used like an object in it.
   * @group Props
   */
  @Input() overlayOptions: OverlayOptions | undefined;
  /**
   * Defines a string that labels the filter input.
   * @group Props
   */
  @Input() ariaFilterLabel: string | undefined;
  /**
   * Defines how the items are filtered.
   * @group Props
   */
  @Input() filterMatchMode:
    | 'contains'
    | 'startsWith'
    | 'endsWith'
    | 'equals'
    | 'notEquals'
    | 'in'
    | 'lt'
    | 'lte'
    | 'gt'
    | 'gte' = 'contains';
  /**
   * Advisory information to display in a tooltip on hover.
   * @group Props
   */
  @Input() tooltip = '';
  /**
   * Position of the tooltip.
   * @group Props
   */
  @Input() tooltipPosition: 'top' | 'left' | 'right' | 'bottom' = 'right';
  /**
   * Type of CSS position.
   * @group Props
   */
  @Input() tooltipPositionStyle = 'absolute';
  /**
   * Style class of the tooltip.
   * @group Props
   */
  @Input() tooltipStyleClass: string | undefined;
  /**
   * Applies focus to the filter element when the overlay is shown.
   * @group Props
   */
  @Input() autofocusFilter = true;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  @Input() autofocus = false;
  /**
   * Defines how the selected items are displayed.
   * @group Props
   */
  @Input() display: string | 'comma' | 'chip' = 'comma';
  /**
   * Defines the autocomplete is active.
   * @group Props
   */
  @Input() autocomplete = 'off';
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  @Input() showClear = false;
  /**
   * @deprecated since v14.2.0, use overlayOptions property instead.
   * Whether to automatically manage layering.
   * @group Props
   */
  @Input() autoZIndex: boolean | undefined;
  /**
   * @deprecated since v14.2.0, use overlayOptions property instead.
   * Base zIndex value to use in layering.
   * @group Props
   */
  @Input() baseZIndex: number | undefined;
  /**
   * Transition options of the show animation.
   * @group Props
   * @deprecated since v14.2.0, use overlayOptions property instead.
   */
  @Input() showTransitionOptions: string | undefined;
  /**
   * Transition options of the hide animation.
   * @group Props
   * @deprecated since v14.2.0, use overlayOptions property instead.
   */
  @Input() hideTransitionOptions: string | undefined;
  /**
   * Label to display when there are no selections.
   * @group Props
   * @deprecated Use placeholder instead.
   */
  @Input() defaultLabel: string | undefined;
  /**
   * Label to display when there are no selections.
   * @group Props
   */
  @Input() placeholder: string | undefined;
  /**
   * An array of objects to display as the available options.
   * @group Props
   */
  @Input() options: any[] | undefined;
  /**
   * When specified, filter displays with this value.
   * @group Props
   */
  @Input() filterValue: string | undefined | null;
  /**
   * Item size of item to be virtual scrolled.
   * @group Props
   * @deprecated use virtualScrollItemSize property instead.
   */
  @Input() itemSize: number | undefined;
  /**
   * Whether all data is selected.
   * @group Props
   */
  @Input() selectAll: boolean | undefined | null;
  /**
   * Fields used when filtering the options, defaults to optionLabel.
   * @group Props
   */
  @Input() focusOnHover = false;
  /**
   * Fields used when filtering the options, defaults to optionLabel.
   * @group Props
   */
  @Input() filterFields: any[] | undefined;
  /**
   * Determines if the option will be selected on focus.
   * @group Props
   */
  @Input() selectOnFocus = false;
  /**
   * Whether to focus on the first visible or selected element when the overlay panel is shown.
   * @group Props
   */
  @Input() autoOptionFocus = true;
  /**
   * Callback to invoke when value changes.
   * @param {MultiSelectChangeEvent} event - Custom change event.
   * @group Emits
   */
  @Output() onChange: EventEmitter<MultiSelectChangeEvent> =
    new EventEmitter<MultiSelectChangeEvent>();
  /**
   * Callback to invoke when data is filtered.
   * @param {MultiSelectFilterEvent} event - Custom filter event.
   * @group Emits
   */
  @Output() onFilter: EventEmitter<MultiSelectFilterEvent> =
    new EventEmitter<MultiSelectFilterEvent>();
  /**
   * Callback to invoke when multiselect receives focus.
   * @param {MultiSelectFocusEvent} event - Custom focus event.
   * @group Emits
   */
  @Output() onFocus: EventEmitter<MultiSelectFocusEvent> =
    new EventEmitter<MultiSelectFocusEvent>();
  /**
   * Callback to invoke when multiselect loses focus.
   * @param {MultiSelectBlurEvent} event - Custom blur event.
   * @group Emits
   */
  @Output() onBlur: EventEmitter<MultiSelectBlurEvent> = new EventEmitter<MultiSelectBlurEvent>();
  /**
   * Callback to invoke when component is clicked.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();
  /**
   * Callback to invoke when input field is cleared.
   * @group Emits
   */
  @Output() onClear: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback to invoke when overlay panel becomes visible.
   * @group Emits
   */
  @Output() onPanelShow: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback to invoke when overlay panel becomes hidden.
   * @group Emits
   */
  @Output() onPanelHide: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Callback to invoke in lazy mode to load new data.
   * @param {MultiSelectLazyLoadEvent} event - Lazy load event.
   * @group Emits
   */
  @Output() onLazyLoad: EventEmitter<MultiSelectLazyLoadEvent> =
    new EventEmitter<MultiSelectLazyLoadEvent>();
  /**
   * Callback to invoke in lazy mode to load new data.
   * @param {MultiSelectRemoveEvent} event - Remove event.
   * @group Emits
   */
  @Output() onRemove: EventEmitter<MultiSelectRemoveEvent> =
    new EventEmitter<MultiSelectRemoveEvent>();
  /**
   * Callback to invoke when all data is selected.
   * @param {MultiSelectSelectAllChangeEvent} event - Custom select event.
   * @group Emits
   */
  @Output() onSelectAllChange: EventEmitter<MultiSelectSelectAllChangeEvent> =
    new EventEmitter<MultiSelectSelectAllChangeEvent>();
}
