import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { jStat } from 'jstat';
import { cloneDeep } from 'lodash';
import regression from 'regression';

@Component({
  selector: 'fund-benchmark-comparison-stats',
  templateUrl: './fund-benchmark-comparison-stats.component.html',
  styleUrls: ['./fund-benchmark-comparison-stats.component.scss']
})
export class FundBenchmarkComparisonStatsComponent implements OnInit, OnChanges {
  @Input() statistics: {
    name: string;
    fund: number[];
    regression: {
      [key: string]: [];
    };
  } = {
    name: '',
    fund: [],
    regression: {}
  };
  columnDefs: any[] = [
    {
      headerName: '',
      field: 'Beta.name',
      suppressHeaderMenuButton: true,
      resizable: false
    }
  ];
  defaultDefs = { flex: 1, minWidth: 50 };
  gridData: any[] = [];
  columns = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statistics && changes.statistics.currentValue) {
      this.statistics = changes.statistics.currentValue;
      this.gridData = this.generateGridData();
    }
  }

  ngOnInit(): void {
    this.gridData = this.generateGridData();
    this.initColumns();
  }

  /**
   * Generate default data for grid
   * @return `Array` data to be rendered on grid
   */
  generateGridData(): any {
    let newData = {};
    let statisticsRegression: {
      [key: string]: { [key: string]: any };
    } = {};
    const convertedData = [];
    const keys = Object.keys(this.statistics.regression).filter(key => !key.includes('_raw'));
    keys.unshift(this.statistics.name);
    let corelation;
    // Create objects with calculated data for columns
    for (const key of keys) {
      corelation = cloneDeep(this.statistics.regression);
      delete corelation[`${this.statistics.name}_raw`];
      statisticsRegression = {
        [key]: {
          'Total Return': this.totalReturn(key),
          'Annualized Return': this.annualizedReturn(key),
          'Avg Ret (Geo)': this.avgReturnGeo(key),
          'Avg Ret (Arithmetic)': this.avgReturn(key),
          'Std Dev (Ann)':
            jStat.stdev(this.statistics.regression[`${key}_raw`] || [0]) * Math.sqrt(12),
          'Sharpe Ratio': 0,
          Correlation: this.hasRegressionKey(key)
            ? jStat.corrcoeff(this.statistics.fund, corelation[`${key}_raw`]) || [0]
            : '',
          Alpha: this.hasRegressionKey(key)
            ? regression.linear(this.statistics.regression[key]).equation[1]
            : '',
          Beta: this.hasRegressionKey(key)
            ? parseFloat(regression.linear(this.statistics.regression[key]).equation[0]).toFixed(2)
            : ''
        }
      };
      this.columns = Object.keys(statisticsRegression[key]);

      // Generate row objects to render on grid
      this.columns.forEach(col => {
        newData = {
          ...newData,
          [col]: {
            header: col,
            name: key,
            data: statisticsRegression[key][col]
          }
        };
      });
      convertedData.push(newData);
    }
    return convertedData;
  }

  /**
   * Check if the regression object has the passed key
   * @param key `string`
   * @return `boolean`
   */
  hasRegressionKey(key: string): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this.statistics.regression.hasOwnProperty(key);
  }

  /**
   * Initiate grid columns
   */
  initColumns(): void {
    this.columns.forEach(col => {
      this.columnDefs.push({
        headerName: `${col}`,
        field: `${col}.data`,
        suppressHeaderMenuButton: true,
        resizable: false,
        editable: false,
        cellRenderer: data => this.cellRendering(data, col)
      });
    });
  }

  /**
   *
   * @param data from grid cell
   * @param col `string` column name
   *
   * @return `string` element to render in cell
   */
  cellRendering(data: any, col: string): string {
    const styling = data.data[col].data < 0 ? 'red' : data.data[col].data === 0 ? '' : 'green';
    const num =
      typeof data.data[col].data !== 'string'
        ? `${parseFloat((data.data[col].data * 100).toString()).toFixed(2)}%`
        : '';
    return `<span class="${styling}">${
      data.colDef.headerName === 'Beta' || data.colDef.headerName === 'Alpha'
        ? data.data[col].data
        : num
    }</span>`;
  }

  /**
   * Calculate annual return
   *
   * @param key of needed benchmark or fund
   */
  annualizedReturn(key: string): number {
    let annReturn = 0;
    if (
      this.statistics.regression[`${key}_raw`] &&
      this.statistics.regression[`${key}_raw`].length > 0
    ) {
      const totReturn = 1 + this.totalReturn(key);
      const factor = 12 / this.statistics.regression[`${key}_raw`].length;
      annReturn = Math.pow(totReturn, factor) - 1;
    }
    return annReturn;
  }

  /**
   * Calculate total return
   *
   * @param key of needed benchmark or fund
   */
  totalReturn(key: string): number {
    return this.hasRegressionKey(`${key}_raw`)
      ? this.statistics.regression[`${key}_raw`].reduce((a, b) => Number(a) * (1 + Number(b)), 1) -
          1
      : 0;
  }

  /**
   * Calculate average (Geo) value for fund and benchmarks
   *
   * @param key `string` name of fund or benchmark
   * @return `number` calculated value
   */
  avgReturnGeo(key: string): number {
    return this.hasRegressionKey(`${key}_raw`)
      ? jStat.geomean(
          this.statistics.regression[`${key}_raw`].reduce((a, b) => {
            a.push(Number(b) + 1);
            return a;
          }, [])
        ) - 1
      : 0;
  }

  /**
   * Calculate average (Arithmetic) value for fund and benchmarks
   *
   * @param key `string` name of fund or benchmark
   * @return `number` calculated value
   */
  avgReturn(key: string): number {
    return this.hasRegressionKey(`${key}_raw`)
      ? this.statistics.regression[`${key}_raw`].reduce((a, b) => Number(a) + Number(b), 0) /
          this.statistics.regression[`${key}_raw`].length
      : 0;
  }
}
