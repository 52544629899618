import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FundPerformanceFacadeService } from '@pinnakl/fund-performance/data-access';
import { groupBy, uniqBy } from 'lodash';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'fund-benchmark-comparison-chart',
  templateUrl: './fund-benchmark-comparison-chart.component.html',
  styleUrls: ['./fund-benchmark-comparison-chart.component.scss']
})
export class FundBenchmarkComparisonChartComponent implements OnInit, OnChanges {
  @Input() benchMarkData: any[];
  @Input() fundsForDropDown: any[];
  @Input() benchMarks: any[];
  @Input() fundsData: any[];
  @Input() selectedFundId: number;
  form: UntypedFormGroup;
  chartData: any[];
  calculatedFunds: any[];
  calculatedBenchmarks: any[];
  date: { startDate: Date; endDate: Date } = { startDate: null, endDate: null };
  statistics: {
    name: string;
    fund: number[];
    regression: {
      [key: string]: [];
    };
  } = {
    name: '',
    fund: [],
    regression: {}
  };
  @Output() emitStatistics = new EventEmitter();
  @Output() fundSelected = new EventEmitter<number>();

  constructor(
    private fb: UntypedFormBuilder,
    private fundPerformanceFacadeService: FundPerformanceFacadeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fundsData && changes.fundsData.currentValue) {
      this.fundsData = changes.fundsData.currentValue;
      this.filterByDate();
    }
    if (changes.selectedFundId && changes.selectedFundId.currentValue) {
      const id = changes.selectedFundId.currentValue;
      this.fundPerformanceFacadeService
        .getOrganizationPerformanceDataById(id)
        .subscribe(organizationPerformanceData => {
          this.fundsData = organizationPerformanceData;
          this.form.controls.startDate.reset(
            new Date(moment().subtract(12, 'months').format('MM-DD-YYYY'))
          );
          this.form.controls.endDate.reset(new Date());
          this.filterByDate();
        });
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.date = {
      startDate: this.form.get('startDate').value,
      endDate: this.form.get('endDate').value
    };
    this.filterByDate();
    this.subscribeToFormChange();
  }

  initForm(): void {
    this.form = this.fb.group({
      id: [this.fundsForDropDown[0]?.id],
      startDate: [new Date(moment().subtract(12, 'months').format('MM-DD-YYYY'))],
      endDate: [new Date()]
    });
  }

  /**
   * Filter grid by date
   */
  filterByDate(): void {
    const benchmark = this.groupAndSortBenchmarks(
      this.benchMarkData.filter(
        (node: any) =>
          moment(node.date).isSameOrAfter(this.date.startDate) &&
          moment(node.date).isSameOrBefore(this.date.endDate)
      )
    );
    const funds = this.fundsData.filter(
      (node: any) =>
        moment(node.date).isSameOrAfter(this.date.startDate) &&
        moment(node.date).isSameOrBefore(this.date.endDate)
    );
    this.prepareChartData(funds, benchmark);
  }

  /**
   * Group and sort benchmarks for proper calculations
   *
   * @param benchmarks raw data form BE
   */
  groupAndSortBenchmarks(benchmarks: any[]): any[] {
    const grouped = groupBy(benchmarks, 'benchmarkid');
    for (const key in grouped) {
      grouped[key] = grouped[key].sort((a, b) => (new Date(b.date) < new Date(a.date) ? 1 : -1));
    }

    // eslint-disable-next-line prefer-spread
    return [].concat.apply([], Object.values(grouped));
  }

  /**
   * Listen to form controls change
   */
  subscribeToFormChange(): void {
    this.form
      .get('id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(id => this.fundSelected.emit(id));
    this.form.controls.startDate.valueChanges.pipe(untilDestroyed(this)).subscribe(data => {
      this.date = { ...this.date, startDate: data };
      if (moment(data).year().toString().length > 3) {
        this.filterByDate();
      }
    });
    this.form.controls.endDate.valueChanges.pipe(untilDestroyed(this)).subscribe(data => {
      this.date = { ...this.date, endDate: data };
      if (moment(data).year().toString().length > 3) {
        this.filterByDate();
      }
      this.filterByDate();
    });
  }

  /**
   * Generate data for chart
   *
   * @param funds
   * @param benchmark
   */
  prepareChartData(funds: any[], benchmark: any[]): void {
    const minDate = funds.length ? moment.min(funds.map(d => moment(d.date))) : null;
    const chart = minDate
      ? benchmark.filter(chartD => moment(chartD.date).isSameOrAfter(minDate))
      : benchmark;
    this.convertDataForStatisticsGrid(funds, benchmark);
    this.calculatedBenchmarks = this.calculationMonthlyReturn(chart, {
      data: this.benchMarks,
      source: 'benchmark',
      id: 'benchmarkid',
      fundId: 'id'
    });
    this.calculatedFunds = this.calculationMonthlyReturn(funds, {
      data: this.fundsForDropDown,
      source: 'targetFund',
      id: 'targetfundid',
      fundId: 'id'
    });
    this.chartData = this.calculatedFunds.length
      ? this.uniqueChartData([...this.calculatedFunds, ...this.calculatedBenchmarks])
      : this.calculatedBenchmarks;

    // Get unique data
    const unique = uniqBy(this.chartData, 'key');

    // Add month before start date with 0 value for chart
    unique.forEach(element => {
      this.chartData.push({
        ...element,
        date: moment(this.date.startDate)
          .subtract(1, 'month')
          .endOf('month')
          .format('MM/D/yyy hh:mm:ss A'),
        [element.key]: 0
      });
    });
  }

  /**
   * Convert data for statistics grid
   * @param funds `Array` of fund raw data for grid
   * @param benchmark `Array` of benchmark raw data for grid
   */
  convertDataForStatisticsGrid(funds: any, benchmark: any): void {
    let convertedFund;
    const filteredFunds = funds.filter(f => +f.targetfundid === this.selectedFundId);
    const convertedBenchmarks =
      benchmark.length && this.benchMarks.length
        ? benchmark
            .map(bench =>
              this.convertData(bench, {
                data: this.benchMarks,
                source: 'benchmark',
                id: 'benchmarkid',
                fundId: 'id'
              })
            )
            .filter(item => Object.keys(item).length)
        : [];
    if (filteredFunds.length) {
      convertedFund = filteredFunds.map(fund =>
        this.convertData(fund, {
          data: this.fundsForDropDown,
          source: 'targetFund',
          id: 'targetfundid',
          fundId: 'id'
        })
      );
    } else {
      const fundToAdd = this.fundsForDropDown
        .filter(fund => fund.id === this.selectedFundId)
        .map(f => ({ [f.targetFund]: 0, key: f.targetFund }))[0];
      convertedFund = uniqBy(
        convertedBenchmarks.map(convertedBenchmark => ({
          ...fundToAdd,
          date: convertedBenchmark.date
        })),
        'date'
      );
    }
    // Group data by date for linear regression
    const groupedData: any = groupBy([...convertedFund, ...convertedBenchmarks], 'date');

    const benchmarksForStatistics = convertedBenchmarks.reduce(
      (accum, data) => ({
        ...accum,
        [data.key]: [...(accum[data.key] || []), +data[data.key]]
      }),
      {}
    );

    this.statistics = {
      name: '',
      fund: [],
      regression: {}
    };
    if (!Object.keys(groupedData).length || !convertedFund.length) {
      this.statistics.name = this.fundsForDropDown.find(
        fund => fund.id === this.selectedFundId
      )?.targetFund;
    }
    // Generate object for statistics grid
    for (const key in groupedData) {
      if (groupedData[key].length === 1) {
        this.statistics.name = convertedFund[0].key;
      }
      groupedData[key].forEach((obj, i) => {
        if (i !== 0 && obj.key !== this.statistics.name) {
          this.statistics = {
            name: convertedFund.length
              ? convertedFund[0].key
              : this.fundsForDropDown.find(fund => fund.id === this.selectedFundId).targetFund,
            fund: convertedFund.map(data => Number(data[data.key])),
            regression: {
              ...(this.statistics.regression || {}),
              [`${groupedData[key][0].key}_raw`]: convertedFund.map(data => Number(data[data.key])),
              [`${obj.key}_raw`]: benchmarksForStatistics[obj.key],
              [obj.key]: [
                ...(this.statistics.regression[obj.key] || []),
                [+obj[obj.key], +groupedData[key][0][groupedData[key][0].key]]
              ]
            }
          };
        }
      });
    }
    this.emitStatistics.emit(this.statistics);
  }

  /**
   * Check for duplicates in chart data
   * @param data
   * @return `Array` of unique values
   */
  uniqueChartData(data: any[]): Array<any> {
    return data.reduce((accum, value) => {
      if (
        !accum.find(
          d => d.date === value.date && d[d.key] === value[value.key] && d.key === value.key
        )
      ) {
        accum.push(value);
      }
      return accum;
    }, []);
  }

  /**
   * Convert raw data
   *
   * @param data `Object` benchmark or fund raw data
   * @param props `Object`  additional data for conversion
   * @return `Array` of calculated data
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  calculationMonthlyReturn(data: any[], props: {}): Array<any> {
    const grouped = groupBy(
      data.reduce((acc, val) => {
        acc.push(this.convertData(val, props));
        return acc.filter(item => Object.keys(item).length);
      }, []),
      'key'
    );
    return data
      .reduce((accum, val) => {
        const newData: any = this.convertData(val, props);
        if (Object.keys(newData).length) {
          const isSame =
            accum.length && accum[accum.length - 1] && accum[accum.length - 1].key === newData.key;
          if (accum.length > 0 && isSame) {
            const index = grouped[newData.key].findIndex(item => item.date === newData.date);
            const slicedData = grouped[newData.key].slice(0, index + 1);
            let sum = 1;
            for (let i = 0; i < slicedData.length; i++) {
              sum = (1 + Number(slicedData[i][slicedData[i].key])) * sum;
            }
            newData[newData.key] = sum - 1;
          }
          accum.push(newData);
        }
        return accum;
      }, [])
      .map(item => ({
        ...item,
        [item.key]: parseFloat((Number(item[item.key]) * 100).toString()).toFixed(2)
      }));
  }

  /**
   * Convert data for chart and statistics grid
   *
   * @param val data to be converted
   * @param props `Object` additional data for conversion
   * @return `Object` converted object
   */
  convertData(
    val: any,
    props: any
  ):
    | {
        [x: number]: number;
        date: any;
        key: any;
      }
    | {
        date?: undefined;
        key?: undefined;
      } {
    const idx = props.data.findIndex(bench => +bench[props.fundId] === +val[props.id]);
    const isPreviousFilteredMoth = moment(val.date).isSame(
      moment(this.date.startDate).subtract(1, 'month').endOf('month')
    );
    return idx !== -1
      ? {
          date: val.date,
          [props.data[idx][props.source]]: isPreviousFilteredMoth ? 0 : +val.monthlyreturn,
          key: props.data[idx][props.source]
        }
      : {};
  }
}
