import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { TargetFund } from '@pinnakl/crm/domain';
import { Observable } from 'rxjs';

@Injectable()
export class FundPerformanceApiService {
  private readonly _crmTargetFundsEndpoint = 'v3/entities/crm_target_funds';
  private readonly _crmTargetFundsReturnsEndpoint = 'v3/entities/crm_target_fund_returns';

  constructor(private readonly wsp: WebServiceProvider) {}

  getTargetFunds(clientPublicId: string | null): Observable<TargetFund[]> {
    const targetFundClientIdFilters = {
      filters: [
        {
          key: 'clientPublicId',
          type: 'EQ',
          value: [clientPublicId]
        }
      ]
    };
    return this.wsp.get<TargetFund[]>({
      endpoint: this._crmTargetFundsEndpoint,
      optionsParams: {
        fields: ['id', 'targetFund', 'viewOrder'],
        ...(clientPublicId ? targetFundClientIdFilters : {})
      }
    });
  }

  getOrganizationPerformanceData(): Observable<any> {
    return this.wsp.get<any>({
      endpoint: this._crmTargetFundsReturnsEndpoint,
      optionsParams: {
        fields: ['id', 'targetfundid', 'date', 'monthlyreturn'],
        filters: []
      }
    });
  }

  getOrganizationPerformanceDataById(fundId: number): Observable<any> {
    return this.wsp.get<any>({
      endpoint: this._crmTargetFundsReturnsEndpoint,
      optionsParams: {
        fields: ['id', 'targetfundid', 'date', 'monthlyreturn'],
        filters: [
          {
            key: 'targetfundid',
            type: 'EQ',
            value: [fundId]
          }
        ]
      }
    });
  }

  updateOrganizationPerformanceData(payload: any): Observable<any> {
    return this.wsp.put<any>({
      endpoint: this._crmTargetFundsReturnsEndpoint,
      body: payload
    });
  }

  createOrganizationPerformanceData(payload: any): Observable<any> {
    return this.wsp.post<any>({
      endpoint: this._crmTargetFundsReturnsEndpoint,
      body: payload
    });
  }
}
