<ng-container
  *ngIf="{
    leftAxisData: leftAxisData$ | async,
    rightAxisData: rightAxisData$ | async,
    loading: loading$ | async
  } as data"
>
  <pinnakl-local-spinner-simple-mode-container
    *ngIf="data.loading; else dataLoaded"
    containerHeight="120px"
    size="70px"
  >
  </pinnakl-local-spinner-simple-mode-container>
  <ng-template #dataLoaded>
    <pinnakl-simple-comparison-chart
      [leftAxisData]="data.leftAxisData"
      [leftAxisTitle]="leftAxisTitle"
      [rightAxisData]="data.rightAxisData"
      [rightAxisTitle]="rightAxisTitle"
    ></pinnakl-simple-comparison-chart>
  </ng-template>
</ng-container>
