<ng-container *ngIf="vm$ | async as vm">
  <div class="row no-gutters comments-table-header">
    <div class="col-8 p-3 font-weight-bold table-header-label">Comment</div>
    <div class="col-2 p-3 font-weight-bold table-header-label text-center">Author name</div>
    <div class="col-2 p-3 font-weight-bold table-header-label">Timestamp</div>
  </div>

  <pinnakl-local-spinner-simple-mode-container
    *ngIf="vm.loading; else dataLoaded"
    containerHeight="120px"
    size="70px"
  >
  </pinnakl-local-spinner-simple-mode-container>

  <ng-template #dataLoaded>
    <div
      *ngIf="vm.folderComments.length === 0"
      class="row no-gutters justify-content-center py-5 no-records-label"
    >
      No records available.
    </div>
    <div
      *ngIf="vm.folderComments.length !== 0"
      class="folder-comments-container"
    >
      <div
        *ngFor="let comment of vm.folderComments"
        class="row no-gutters comment-row cursor-pointer"
      >
        <div class="col-8 pl-2 py-2">{{ comment.comment }}</div>
        <div class="col-2 pl-2 py-2 text-center">{{ comment.authorName }}</div>
        <div class="col-2 pl-2 py-2">
          {{ comment.timestamp | utc | date: 'MMMM dd, y &nbsp;&nbsp; h:mm a' }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
