import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PnklComparisonAxisData } from '@pinnakl/charts';
import { SecurityDetailsModalFacadeService } from '@pinnakl/poems/modals/security-details/data-access';
import { SecurityPositionTimeItem, SecurityPriceTimeItem } from '@pinnakl/poems/timeseries/domain';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { Observable, map } from 'rxjs';
import { filter } from 'rxjs/operators';

const transformPositionTimeItemToChartComparisonAxisData = ({
  date,
  quantity,
  tradeQuantity
}: SecurityPositionTimeItem): PnklComparisonAxisData => ({
  date: new Date(date),
  value: quantity,
  flagQuantity: tradeQuantity || undefined
});
const transformPriceTimeItemToChartComparisonAxisData = ({
  date,
  price
}: SecurityPriceTimeItem): PnklComparisonAxisData => ({
  date: new Date(date),
  value: price
});

@Component({
  selector: 'pinnakl-security-details-position-history',
  templateUrl: './security-details-position-history.component.html',
  styleUrls: ['./security-details-position-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityDetailsPositionHistoryComponent {
  readonly leftAxisTitle = 'Positions';
  readonly rightAxisTitle = 'Price';
  loading$: Observable<boolean>;
  leftAxisData$: Observable<PnklComparisonAxisData[]>;
  rightAxisData$: Observable<PnklComparisonAxisData[]>;

  constructor(
    private readonly securityDetailsModalFacadeService: SecurityDetailsModalFacadeService
  ) {
    this.loading$ = this.securityDetailsModalFacadeService.timeseriesLoading$;
    this.leftAxisData$ = this.securityDetailsModalFacadeService.timeseriesPositions$.pipe(
      filter(isNeitherNullNorUndefined),
      map(positions => positions.map(transformPositionTimeItemToChartComparisonAxisData)),
      map(values => {
        const valuesToCopy: PnklComparisonAxisData[] = [];
        let date: Date | null = null;
        for (let i = values.length - 1; i >= 0; i--) {
          const currDate = values[i].date;
          if (date !== null && date.getTime() !== currDate.getTime()) {
            break;
          }
          date = currDate;
          const dateToPush = new Date(currDate.getTime() + 86400000);
          valuesToCopy.push({
            ...values[i],
            date: dateToPush,
            flagQuantity: undefined
          });
        }

        return values.concat(valuesToCopy);
      })
    );
    this.rightAxisData$ = this.securityDetailsModalFacadeService.timeseriesPrices$.pipe(
      filter(isNeitherNullNorUndefined),
      map(prices => prices.map(transformPriceTimeItemToChartComparisonAxisData))
    );
  }
}
