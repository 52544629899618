import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatest, concatMap, exhaustMap, map, of, switchMap, take } from 'rxjs';
import { FoldersApiService } from './folders-api.service';
import {
  createFolder,
  deleteAllowedFolder,
  deleteFolder,
  folderCreated,
  folderDataLoaded,
  folderDeleteAllowed,
  folderDeleteAllowedFailure,
  folderDeleteFailure,
  folderDeleted,
  folderUpdateFailure,
  folderUpdated,
  foldersLoaded,
  loadFolderData,
  loadFolders,
  updateFolder
} from './folders.actions';

@Injectable()
export class FoldersEffects {
  loadFolders$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFolders),
      switchMap(() => this.api.getFolders()),
      map(folders => foldersLoaded({ folders }))
    )
  );

  loadFolderData$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFolderData),
      exhaustMap(() =>
        combineLatest([this.api.getAllCustomValues(), this.api.getFolderDefinitions()]).pipe(
          take(1),
          map(([allCustomValues, customAttributesList]) =>
            folderDataLoaded({
              customFolderData: {
                allCustomValues,
                customAttributes: FoldersApiService.combineCustomAttributesWithCustomValues(
                  customAttributesList,
                  allCustomValues
                )
              }
            })
          )
        )
      )
    )
  );

  createFolder$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFolder),
      concatMap(({ folder }) =>
        this.api.createFolder({
          col1: folder.folderCode,
          col2: folder.folderName,
          col3: folder.strategy,
          col4: folder.trader,
          col5: folder.analyst
        })
      ),
      map(folder => folderCreated({ folder })),
      catchError(error => of(folderUpdateFailure({ error })))
    )
  );

  updateFolder$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFolder),
      concatMap(({ folder }) =>
        this.api.updateFolder({
          id: folder.id,
          col1: folder.folderCode,
          col2: folder.folderName,
          col3: folder.strategy,
          col4: folder.trader,
          col5: folder.analyst
        })
      ),
      map(folder => folderUpdated({ folder })),
      catchError(error => of(folderUpdateFailure({ error })))
    )
  );

  checkDeleteFolder$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAllowedFolder),
      concatMap(({ id }) =>
        this.api.confirmDeleteForFolder(id).pipe(
          map(folder =>
            folder ? folderDeleteAllowed({ id }) : folderDeleteAllowedFailure({ id })
          ),
          catchError(error => of(folderDeleteAllowedFailure({ id, error })))
        )
      )
    )
  );

  deleteFolder$$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFolder),
      concatMap(({ id }) =>
        this.api.deleteFolder(id).pipe(
          map(() => folderDeleted({ id })),
          catchError(error => of(folderDeleteFailure({ id, error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: FoldersApiService
  ) {}
}
