<div *ngIf="showAllText">
  <div
    [innerHTML]="safeHtml"
    [ngClass]="textClass"
  ></div>
  <span
    (click)="collapse()"
    *ngIf="html.length > peekLength"
    class="cursor-pointer subject-person"
  >
    Hide
  </span>
</div>
<div *ngIf="!showAllText">
  <div
    [innerHTML]="preview"
    [ngClass]="textClass"
    class="overflow-hidden"
  ></div>
  <span
    (click)="expand()"
    *ngIf="html.length > peekLength"
    class="cursor-pointer subject-person"
  >
    ...More
  </span>
</div>
