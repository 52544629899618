<div class="col-md-12 bg-white py-4 wrap">
  <div class="heading">
    <h3>Performance data</h3>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex align-items-center">
      <div class="col-md-12">
        <form [formGroup]="form">
          <div class="row horizontal-align-end no-gutters">
            <div class="col-4 portfolioType">
              <span class="portfolioHeader">Portfolio Type</span>
              <label for="fund">
                <input
                  formControlName="portfolioType"
                  id="fund"
                  type="radio"
                  value="fund"
                />
                <span>Fund</span>
              </label>
              <label for="benchmark">
                <input
                  formControlName="portfolioType"
                  id="benchmark"
                  type="radio"
                  value="benchmark"
                />
                <span>Benchmark</span>
              </label>
            </div>
            <div class="col-4 pl-4">
              <pinnakl-input
                [form]="form"
                controlName="startDate"
                label="Start Date"
                type="date"
              ></pinnakl-input>
            </div>
            <div class="col-4 pl-4">
              <pinnakl-input
                [form]="form"
                controlName="endDate"
                label="End Date"
                type="date"
              ></pinnakl-input>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="grid-wrap">
    <pinnakl-grid
      [columnDefs]="columnDefs"
      [defaultColDefs]="defaultDefs"
      [gridOptions]="{}"
      [headerHeight]="30"
      [rowData]="gridData"
      [rowHeight]="30"
      [styleClass]="investorStyle"
      [style]="{ height: '100%' }"
    >
    </pinnakl-grid>
  </div>
</div>
