import { KnownCurrency, UnknownCurrency } from './currency.model';

export const UNKNOWN_CURRENCY: UnknownCurrency = 'UNKNOWN';

export const currencyIcons: Record<KnownCurrency | UnknownCurrency, string> = {
  [KnownCurrency.AED]: 'currencies.svg#AED',
  [KnownCurrency.AUD]: 'currencies.svg#AUD',
  [KnownCurrency.BRL]: 'currencies.svg#BRL',
  [KnownCurrency.CAD]: 'currencies.svg#CAD',
  [KnownCurrency.CHF]: 'currencies.svg#CHF',
  [KnownCurrency.CLP]: 'currencies.svg#CLP',
  [KnownCurrency.CNY]: 'currencies.svg#CNY',
  [KnownCurrency.CZK]: 'currencies.svg#CZK',
  [KnownCurrency.DKK]: 'currencies.svg#DKK',
  [KnownCurrency.EUR]: 'currencies.svg#EUR',
  [KnownCurrency.GBP]: 'currencies.svg#GBP',
  [KnownCurrency.HKD]: 'currencies.svg#HKD',
  [KnownCurrency.HUF]: 'currencies.svg#HUF',
  [KnownCurrency.ILS]: 'currencies.svg#ILS',
  [KnownCurrency.INR]: 'currencies.svg#INR',
  [KnownCurrency.JPY]: 'currencies.svg#JPY',
  [KnownCurrency.KRW]: 'currencies.svg#KRW',
  [KnownCurrency.MXN]: 'currencies.svg#MXN',
  [KnownCurrency.NOK]: 'currencies.svg#NOK',
  [KnownCurrency.NZD]: 'currencies.svg#NZD',
  [KnownCurrency.PLN]: 'currencies.svg#PLN',
  [KnownCurrency.RUB]: 'currencies.svg#RUB',
  [KnownCurrency.SEK]: 'currencies.svg#SEK',
  [KnownCurrency.SGD]: 'currencies.svg#SGD',
  [KnownCurrency.TRY]: 'currencies.svg#TRY',
  [KnownCurrency.TWD]: 'currencies.svg#TWD',
  [KnownCurrency.UAH]: 'currencies.svg#UAH',
  [KnownCurrency.USD]: 'currencies.svg#USD',
  [KnownCurrency.ZAR]: 'currencies.svg#ZAR',
  UNKNOWN: 'currencies.svg#UNKNOWN'
};
