export interface CurrencyModel {
  id: number;
  currency: string;
}

export enum KnownCurrency {
  AED = 'AED',
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  HUF = 'HUF',
  ILS = 'ILS',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  PLN = 'PLN',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  TRY = 'TRY',
  TWD = 'TWD',
  UAH = 'UAH',
  USD = 'USD',
  ZAR = 'ZAR'
}

export type UnknownCurrency = 'UNKNOWN';
