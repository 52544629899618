import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

@Component({
  selector: 'pinnakl-rich-content-viewer',
  templateUrl: 'peek-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeekContentComponent implements OnChanges {
  @Input() peekLength: number = Number.POSITIVE_INFINITY;
  @Input() content: string;
  @Output() viewChange = new EventEmitter<{ isExpanded: boolean }>();
  isContentHtml: boolean;

  ngOnChanges() {
    if (!this.content || !this.peekLength) return;
    this.isContentHtml = this.checkIfContentHasHtml(this.content);
  }

  handleViewChanged(event: { isExpanded: boolean }): void {
    this.viewChange.emit(event);
  }

  private checkIfContentHasHtml(content: string): boolean {
    const regEx = /<\//;
    return regEx.test(content);
  }
}
