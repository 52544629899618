import { inject, Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { SecurityBase, SecurityPostPayload } from '@pinnakl/securities/domain';
import { V3_ENDPOINT, V4_ENDPOINT } from '@pinnakl/shared/constants';
import { DataService, Filter } from '@pinnakl/shared/signal-store';
import { SecuritySearchResult } from '@pinnakl/shared/types';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable, tap } from 'rxjs';

export type SecuritiesFilter = Filter;

@Injectable({
  providedIn: 'root'
})
export class SecuritiesApiService implements DataService<SecurityBase, SecuritiesFilter> {
  private readonly securitiesV4Endpoint = `${V4_ENDPOINT}securities`;
  private readonly securitiesV3SearchEndpoint = `${V3_ENDPOINT}security_search`;
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);

  async loadById(id: number): Promise<SecurityBase> {
    try {
      return await firstValueFrom(this.findById(id));
    } catch (error) {
      this.toast.warning('Unable to load security.' + getErrorMessage('', error));
      throw error;
    }
  }

  async create(data: SecurityPostPayload): Promise<SecurityBase> {
    try {
      return await firstValueFrom(this.createEntity(data));
    } catch (error) {
      this.toast.warning('Unable to create security.' + getErrorMessage('', error));
      throw error;
    }
  }

  async updateById(id: number, data: SecurityBase): Promise<SecurityBase> {
    try {
      return await firstValueFrom(this.updateEntity(id, data));
    } catch (error) {
      this.toast.warning(`Unable to update security'}.` + getErrorMessage('', error));
      throw error;
    }
  }

  async removeById(id: number): Promise<void> {
    try {
      return await firstValueFrom(this.removeEntity(id));
    } catch (error) {
      this.toast.warning('Unable to delete security. ' + getErrorMessage('', error));
      throw error;
    }
  }

  public findById(id: number): Observable<SecurityBase> {
    return this.wsp.get<SecurityBase>({ endpoint: `${this.securitiesV4Endpoint}/${id}` });
  }

  public createEntity(body: SecurityPostPayload): Observable<SecurityBase> {
    return this.wsp.post<SecurityBase>({ endpoint: this.securitiesV4Endpoint, body }).pipe(
      tap(() => {
        this.toast.success(`${body.ticker} has been created`);
      })
    );
  }

  public updateEntity(id: number, body: SecurityBase): Observable<SecurityBase> {
    return this.wsp
      .put<SecurityBase>({ endpoint: `${this.securitiesV4Endpoint}/${id}`, body })
      .pipe(
        tap(() => {
          this.toast.success(`${body.description} has been updated`);
        })
      );
  }

  async searchSecurities(
    text: string,
    assetType: string | null,
    additionalFilters: {
      key: string;
      type: string;
      value: string[];
    }[] = [],
    includeCheckForExpiration = false
  ): Promise<SecuritySearchResult[]> {
    const filters = [{ key: 'searchstring', type: 'LIKE', value: [text] }].concat(
      additionalFilters
    );
    if (assetType) {
      filters.push({ key: 'assettype', type: 'EQ', value: [assetType] });
      if (includeCheckForExpiration) {
        filters.push({
          key: 'checkforexpiration',
          type: 'EQ',
          value: [`${['option', 'future'].includes(assetType).toString()}`]
        });
      }
    }
    return firstValueFrom(
      this.wsp.get<SecuritySearchResult[]>({
        endpoint: this.securitiesV3SearchEndpoint,
        optionsParams: {
          filters
        }
      })
    );
  }

  async loadAffectedSecurityTradesAmount(securityId: number): Promise<{ affectedCount: 0 }> {
    try {
      return await this.wsp.getHttp<{ affectedCount: 0 }>({
        endpoint: `${this.securitiesV4Endpoint}/${securityId}/affected-trades`
      });
    } catch (error) {
      this.toast.error(
        'Unable to load affected security trades amount. ' + getErrorMessage('', error)
      );
      throw error;
    }
  }

  private removeEntity(id: number): Observable<void> {
    return this.wsp.delete<void>({ endpoint: `${this.securitiesV4Endpoint}/${id}` });
  }
}
