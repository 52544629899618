import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HtmlHelperService } from '@pinnakl/shared/util-providers';

@Component({
  selector: 'peek-html',
  templateUrl: 'peek-html.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HtmlHelperService]
})
export class PeekHtmlComponent implements OnChanges {
  @Input() peekLength = 100;
  @Input() html = '';
  @Input() textClass = '';
  @Output() viewChanged = new EventEmitter<{ isExpanded: boolean }>();
  preview: SafeHtml;
  safeHtml: SafeHtml;
  nonHtmlContentLength: number;
  showAllText: boolean;

  constructor(
    protected sanitizer: DomSanitizer,
    protected htmlService: HtmlHelperService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.setValues();
  }

  setValues(): void {
    this.preview = this.sanitizer.bypassSecurityTrustHtml(this.trimHtmlForPreview());
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.html);
    this.nonHtmlContentLength = this.calcNonHtmlContentLength();
    this.showAllText = this.nonHtmlContentLength <= this.peekLength;
    this.viewChanged.emit({ isExpanded: this.showAllText });
  }

  calcNonHtmlContentLength(): number {
    return this.htmlService.getPlainText(this.html).length;
  }

  trimHtmlForPreview(): string {
    return this.htmlService.trimHtmlContent(this.html, this.peekLength);
  }

  expand() {
    this.showAllText = true;
    this.viewChanged.emit({ isExpanded: true });
  }

  collapse() {
    this.showAllText = false;
    this.viewChanged.emit({ isExpanded: false });
  }
}
