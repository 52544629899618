import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BenchmarksFacadeService } from '@pinnakl/benchmarks/data-access';
import { CrmClientConfig, InvestorReturns } from '@pinnakl/crm/domain';
import { FundPerformanceFacadeService } from '@pinnakl/fund-performance/data-access';
import moment from 'moment';
import { of, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'fund-performance-raw-data',
  templateUrl: './fund-performance-raw-data.component.html',
  styleUrls: ['./fund-performance-raw-data.component.scss']
})
export class FundPerformanceRawDataComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() investorReturns: InvestorReturns[];
  @Input() benchMarks: any[];
  @Input() clientConfig: CrmClientConfig;
  @Input() statistics;
  @Input() accessEditableCell = true;
  @Input() selectedFundId!: number;
  @Input() investorStyle = 'investor-performance-grid';
  @Output() portfolioTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() gridEdit: EventEmitter<any> = new EventEmitter<any>();
  form: UntypedFormGroup;
  columnDefs: any[] = [
    {
      headerName: '',
      field: 'Jan.year',
      suppressHeaderMenuButton: true,
      resizable: false
    }
  ];
  defaultDefs = { flex: 1, minWidth: 45 };
  portFolioType = 'targetfundid';
  style = 'investor-performance-grid';
  payload: {
    [key: string]: string;
    date: string;
    monthlyreturn: string;
    id?: string;
  } = { [this.portFolioType]: null, date: null, monthlyreturn: null, id: null };
  date: { startDate: Date; endDate: Date } = { startDate: null, endDate: null };
  gridData;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly fundPerformanceFacadeService: FundPerformanceFacadeService,
    private readonly benchmarksFacadeService: BenchmarksFacadeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.benchMarks) {
      this.benchMarks = changes.benchMarks.currentValue;
    }
    if (changes.investorReturns) {
      this.investorReturns = changes.investorReturns.currentValue;
      this.gridData = this.prepareGridData(this.investorReturns);
      this.filterByDate();
    }
    if (changes.selectedFundId && changes.selectedFundId.currentValue) {
      const id = changes.selectedFundId.currentValue;
      of(id)
        .pipe(
          switchMap(id => {
            if (this.portFolioType === 'benchmarkid') {
              return this.benchmarksFacadeService.getOrganizationComparisonStatsById(id);
            } else {
              return this.fundPerformanceFacadeService.getOrganizationPerformanceDataById(id);
            }
          })
        )
        .subscribe(response => {
          this.investorReturns = response;
          this.gridData = this.prepareGridData(this.investorReturns);
          this.filterByDate();
        });
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.initColumns();
    this.date = {
      startDate: this.form.get('startDate').value,
      endDate: this.form.get('endDate').value
    };
    this.listenToFormChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.filterByDate();
    });
  }

  /**
   * Listen to form controls changes
   */
  listenToFormChanges(): void {
    this.gridData = this.prepareGridData(this.investorReturns);

    this.form.controls.startDate.valueChanges.subscribe(data => {
      this.date = { ...this.date, startDate: data };
      this.filterByDate();
    });
    this.form.controls.endDate.valueChanges.subscribe(data => {
      this.date = { ...this.date, endDate: data };
      this.filterByDate();
    });
    this.form.controls.portfolioType.valueChanges.subscribe(data => {
      this.portFolioType = data !== 'fund' ? 'benchmarkid' : 'targetfundid';
      this.portfolioTypeChanged.emit(data);
      this.columnDefs = this.columnDefs.map(def => ({
        ...def,
        editable: data !== 'benchmark' && this.accessEditableCell
      }));
      this.filterByDate();
    });
  }

  /**
   * Convert data to display on grid
   *
   * @param investorData - raw data for grid
   * @return `Array` of row objects for grid
   */
  prepareGridData(investorData: any[]): Array<any> {
    let newData = {};
    const convertedData = [];
    if (investorData.length) {
      // Generate objects for grid
      (investorData as any).forEach(item => {
        const month = moment(item.date).format('MMM');
        const year = moment(item.date).format('YYYY');
        if (!newData[year]) {
          newData = Object.assign(newData, {
            [year]: { [month]: { year, month, ...item } }
          });
        } else if (!newData[year][month]) {
          newData[year] = {
            ...newData[year],
            [month]: { year, month, ...item }
          };
        }
      });
    } else {
      newData = this.generateDefaultData();
    }

    Object.keys(newData).forEach(key =>
      convertedData.push(this.addMissingMonths(newData[key], key))
    );
    return convertedData.reverse();
  }

  /**
   * Filter grid by date
   */
  filterByDate(): void {
    const filtered = this.investorReturns.filter(
      (node: any) =>
        moment(node.date).isSameOrAfter(this.date.startDate) &&
        moment(node.date).isSameOrBefore(this.date.endDate)
    );
    this.gridData = this.prepareGridData(filtered);
  }

  /**
   * Add month that has no data to display on grid with 0 value
   * @param data - grid data object
   * @param year
   * @return `Object` grid column object
   */
  addMissingMonths(data: any, year: any): any {
    const ytdDataArr = [];
    moment.monthsShort().forEach(month => {
      // eslint-disable-next-line no-prototype-builtins
      if (!data.hasOwnProperty(month)) {
        data[month] = {
          year,
          month,
          monthlyreturn: 0,
          date: moment().month(month).year(year).endOf('month').format('MM/DD/YYYY')
        };
      }

      ytdDataArr.push(+data[month].monthlyreturn);
      for (const item in data) {
        data['YTD'] = {
          value: ytdDataArr.reduce((ac, val) => ac * (1 + val), 1) - 1
        };
      }
    });
    return data;
  }

  /**
   * Generate default data for grid
   * @return `Object` grid column objects
   */
  generateDefaultData(): any {
    let data = {};
    moment.monthsShort().forEach(month => {
      const year = new Date().getFullYear();
      if (!data[year]) {
        data = Object.assign(data, {
          [year]: {
            [month]: {
              year,
              month,
              monthlyreturn: 0,
              date: moment().month(month).year(year).endOf('month').format('MM/DD/YYYY')
            }
          }
        });
      } else if (!data[year][month]) {
        data[year] = {
          ...data[year],
          [month]: {
            year,
            month,
            monthlyreturn: 0,
            date: moment().month(month).year(year).endOf('month').format('MM/DD/YYYY')
          }
        };
      }
    });
    return data;
  }

  /**
   * Initiate form on page load
   */
  initForm(): void {
    this.form = this.fb.group({
      portfolioType: ['fund'],
      startDate: [new Date(moment().subtract(120, 'months').format('MM-DD-YYYY'))],
      endDate: [new Date()]
    });
  }

  /**
   * Initiate grid columns
   */
  initColumns(): void {
    moment.monthsShort().forEach(month => {
      this.columnDefs.push({
        headerName: month,
        field: `${month}.monthlyreturn`,
        suppressHeaderMenuButton: true,
        resizable: false,
        editable: this.form.get('portfolioType').value !== 'benchmark' && this.accessEditableCell,
        onCellValueChanged: this.cellValueChanged.bind(this),
        cellRenderer: data => {
          const red = +data.value < 0 ? 'red' : +data.value === 0 ? '' : 'green';
          const num = +data.value * 100;
          return `<span class="${red}">${parseFloat(num.toString()).toFixed(2) || 0}%</span>`;
        }
      });
    });
    this.columnDefs.push({
      headerName: 'YTD Ret',
      field: 'YTD.value',
      suppressHeaderMenuButton: true,
      resizable: false,
      cellRenderer: data => {
        const num = +data.value * 100;
        const style = +data.value < 0 ? 'ytd-red' : 'ytd';
        return `<div class="${style}">${parseFloat(num.toString()).toFixed(2) || 0}</div>`;
      },
      editable: this.form.get('portfolioType').value !== 'benchmark' && this.accessEditableCell,
      onCellValueChanged: this.cellValueChanged.bind(this)
    });
  }

  /**
   * Listen to grid cell changes
   *
   * @param gridData - grid data object
   */
  cellValueChanged(gridData: any): void {
    const {
      column: {
        userProvidedColDef: { headerName }
      }
    } = gridData;
    this.payload = {
      [this.portFolioType]: this.selectedFundId.toString(),
      date: moment(gridData.data[headerName].date).endOf('month').format('MM/DD/YYYY'),
      monthlyreturn: gridData.newValue
    };
    // eslint-disable-next-line no-prototype-builtins
    if (gridData.data[headerName].hasOwnProperty('id')) {
      this.payload.id = gridData.data[headerName].id;
    }
    this.editData(this.payload);
  }

  /**
   * Edit cell data on double click
   *
   * @param payload - data to save
   */
  editData(payload: any): void {
    let request;
    if (payload?.id) {
      if (this.portFolioType === 'benchmarkid') {
        request = this.benchmarksFacadeService.updateOrganizationPerformanceData(payload);
      } else {
        request = this.fundPerformanceFacadeService.updateOrganizationPerformanceData(payload);
      }
    } else {
      if (this.portFolioType === 'benchmarkid') {
        request = this.benchmarksFacadeService.createOrganizationPerformanceData(payload);
      } else {
        request = this.fundPerformanceFacadeService.createOrganizationPerformanceData(payload);
      }
    }
    request.pipe(untilDestroyed(this)).subscribe(() => this.gridEdit.emit(payload.targetfundid));
  }
}
