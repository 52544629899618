<div class="col-md-12 py-4 bg-white">
  <div class="col-12 heading">
    <h3>Comparison Stats</h3>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex align-items-center">
      <div class="col-md-12">
        <form [formGroup]="form">
          <div class="row horizontal-align-end no-gutters">
            <div class="col-6">
              <pinnakl-input
                [dropdownOptions]="{
                  modelProperty: 'id',
                  viewProperty: 'targetFund'
                }"
                [dropdownSource]="fundsForDropDown"
                [form]="form"
                controlName="id"
                label="Fund"
                type="dropdown"
              >
              </pinnakl-input>
            </div>
            <div class="col-3 pl-4">
              <pinnakl-input
                [form]="form"
                controlName="startDate"
                label="Start Date"
                type="date"
              ></pinnakl-input>
            </div>
            <div class="col-3 pl-4">
              <pinnakl-input
                [form]="form"
                controlName="endDate"
                label="End Date"
                type="date"
              ></pinnakl-input>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 chart">
      <pnkl-line-stockchart-date
        [chartItemsLimit]="6"
        [colors]="['#3aa3ff', '#7f3aff', '#e7aa1e', '#40ea41', '#ff2c6a', '#ff782a']"
        [defaultOption]="'Monthly'"
        [hideDailyAndWeeklyOptions]="true"
        [showLabel]="true"
        [values]="chartData"
      ></pnkl-line-stockchart-date>
    </div>
  </div>
</div>
