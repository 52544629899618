import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { AuthService } from '@pinnakl/auth/providers';
import {
  PmsPositionsFacadeService,
  PortfolioLatestPricesFacadeService
} from '@pinnakl/pms/data-access';
import { Account } from '@pinnakl/poems/accounts/domain';
import {
  SecurityDetailsModalDataProcessor,
  SecurityDetailsModalFacadeService
} from '@pinnakl/poems/modals/security-details/data-access';
import {
  RealtimePriceOverrideCreateObj,
  SecurityDetailsComponentData
} from '@pinnakl/poems/modals/security-details/domain';
import { PositionsGeneralFacadeService } from '@pinnakl/poems/positions/data-access';
import {
  ClosePriceOverrideFacadeService,
  RealtimePriceOverrideFacadeService
} from '@pinnakl/poems/pricing/data-access';
import { PriceEventsStreamFacadeService } from '@pinnakl/poems/streams/price/data-access';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { ModalMinimizeService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, map, Observable, take } from 'rxjs';
import { filter } from 'rxjs/operators';

interface VM {
  ticker: string;
  securityMarketId: string;
  underlyingSecurityId?: string;
  disableSearchSecurities: boolean;
  minimizedView: boolean;
}

@Component({
  selector: 'pinnakl-security-details-container',
  templateUrl: './security-details-container.component.html',
  styleUrls: ['./security-details-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityDetailsModalContainerComponent implements OnInit, OnDestroy {
  private readonly data: SecurityDetailsComponentData;
  isFoldersDisabled?: boolean;
  isExposureSummaryDisabled?: boolean;
  vm$: Observable<VM>;
  availableAccountsList?: Account[];
  selectedAccountsControl = new FormControl<Account[]>([]);
  destroyRef: DestroyRef = inject(DestroyRef);

  @HostBinding('class') get classes(): string {
    const minimizedView = this.modalMinimizeService.minimizedView$.value;
    return `block ${minimizedView ? 'p-3' : 'p-5'}`;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly toast: PinnaklUIToastMessage,
    private readonly positionsFacade: PmsPositionsFacadeService,
    private readonly securityDetailsFacade: SecurityDetailsModalFacadeService,
    private readonly config: DynamicDialogConfig<SecurityDetailsComponentData>,
    private readonly positionsGeneralFacadeService: PositionsGeneralFacadeService,
    private readonly priceEventsStreamFacadeService: PriceEventsStreamFacadeService,
    private readonly closePriceOverrideFacadeService: ClosePriceOverrideFacadeService,
    private readonly securityDetailsModalDataProcessor: SecurityDetailsModalDataProcessor,
    private readonly realtimePriceOverrideFacadeService: RealtimePriceOverrideFacadeService,
    private readonly portfolioLatestPricesFacadeService: PortfolioLatestPricesFacadeService,
    private readonly modalMinimizeService: ModalMinimizeService,
    private readonly vcr: ViewContainerRef
  ) {
    this.authService.getAccessToken().subscribe(token => {
      this.priceEventsStreamFacadeService.subscribeToStream(token);
    });
    if (!this.config.data) {
      throw new Error('No data provided into security details modal');
    }
    this.data = this.config.data;
    this.modalMinimizeService.setDialogInstance(this.dialogRef, this.vcr);
    this.availableAccountsList = this.data.availableAccounts?.sort(
      ({ accountCode: codeA }, { accountCode: codeB }) => (codeA < codeB ? -1 : 1)
    );
    this.vm$ = combineLatest([
      this.securityDetailsFacade.activeTicker$.pipe(filter(isNeitherNullNorUndefined)),
      this.securityDetailsFacade.activeSecurityMarketId$.pipe(filter(isNeitherNullNorUndefined)),
      this.securityDetailsFacade.activeUnderlyingSecurityId$,
      this.modalMinimizeService.minimizedView$
    ]).pipe(
      map(
        ([ticker, securityMarketId, underlyingSecurityId, minimizedView]): VM => ({
          ticker,
          securityMarketId,
          underlyingSecurityId,
          disableSearchSecurities: !!this.data.disableSearchSecurities,
          minimizedView
        })
      )
    );

    this.selectedAccountsControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(accounts => this.securityDetailsFacade.setSelectedAccounts(accounts ?? []));

    this.isFoldersDisabled = this.data.folderId === undefined;
    this.isExposureSummaryDisabled = this.data.isExposureSummaryDisabled;
    this.securityDetailsFacade.setActiveTicker(this.data.ticker ?? '');

    if (this.data.securityMarketId) {
      this.securityDetailsFacade.setActiveSecurityMarketId(this.data.securityMarketId);
    }

    if (this.data.underlyingSecurityId) {
      this.securityDetailsFacade.setActiveUnderlyingSecurityId(this.data.underlyingSecurityId);
    }

    this.realtimePriceOverrideFacadeService.loadRealtimePriceOverrideBySecurityMarketId(
      this.data.securityMarketId,
      true
    );
  }

  ngOnInit(): void {
    this.portfolioLatestPricesFacadeService.loadPortfolioLatestPrices();
    this.selectedAccountsControl.patchValue(this.data.selectedAccounts ?? []);
    this.securityDetailsModalDataProcessor.processData(this.data);
  }

  ngOnDestroy(): void {
    this.securityDetailsModalDataProcessor.resetData();
  }

  saveNewMarkValue(valueToSet: null | RealtimePriceOverrideCreateObj): void {
    const securityMarketId = this.data.securityMarketId;

    if (valueToSet) {
      this.realtimePriceOverrideFacadeService.createRealtimePriceOverride({
        securityMarketId: +securityMarketId,
        currentPrice: valueToSet.oldValue,
        overridePrice: valueToSet.value,
        currencyCode: valueToSet.currencyCode,
        overrideExpiration: valueToSet.type
      });
    } else {
      this.realtimePriceOverrideFacadeService.overridesMap$
        .pipe(
          take(1),
          map(overridesMap => overridesMap[securityMarketId]),
          filter(isNeitherNullNorUndefined),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(override => {
          override.currentPrice &&
            this.positionsFacade.updatePositionBySecurityMarketId(+override.securityMarketId, {
              mark: override.currentPrice
            });

          this.realtimePriceOverrideFacadeService.deleteRealtimePriceOverride(securityMarketId);
        });
    }
  }

  saveNewPrevCloseValue(valueToSet: null | { value: number; currency: string }): void {
    const securityMarketId = this.data.securityMarketId;

    if (valueToSet) {
      this.closePriceOverrideFacadeService
        .createOverrideStatus({
          securityMarketId: +securityMarketId,
          price: valueToSet.value,
          currency: valueToSet.currency
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.toast.success('Previous close price overridden');
          this.positionsFacade.updatePositionBySecurityMarketId(+securityMarketId, {
            pricesodlocal: valueToSet.value
          });
          this.positionsGeneralFacadeService.updatePriceLastForSecurityMarketIdInMostRecentPrice(
            securityMarketId,
            valueToSet.value
          );
          this.positionsGeneralFacadeService.loadPositionBaseInfoBySecurityMarketId(
            this.data.securityMarketId,
            true
          );
        });
    }
  }

  mouseDownEvent(event: MouseEvent): void {
    this.modalMinimizeService.initDrag(event);
  }

  minimizeDialog(): void {
    this.modalMinimizeService.minimizeDialog();
  }

  maximizeDialog(): void {
    this.modalMinimizeService.maximizeDialog();
  }

  closeModal(type?: string): void {
    this.dialogRef.close(type);
  }
}
