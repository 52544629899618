import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BenchmarksApiService, ClientBenchMark } from './benchmarks-api.service';

@Injectable()
export class BenchmarksFacadeService {
  constructor(private readonly api: BenchmarksApiService) {}

  getBenchmarks(clientPublicId: string | null): Observable<ClientBenchMark[]> {
    return this.api.getBenchmarks(clientPublicId);
  }

  getOrganizationComparisonStats(): Observable<any> {
    return this.api.getOrganizationComparisonStats();
  }

  getOrganizationComparisonStatsById(id: number): Observable<any> {
    return this.api.getOrganizationComparisonStatsById(id);
  }

  updateOrganizationPerformanceData(payload): Observable<any> {
    return this.api.updateOrganizationPerformanceData(payload);
  }

  createOrganizationPerformanceData(payload): Observable<any> {
    return this.api.createOrganizationPerformanceData(payload);
  }
}
